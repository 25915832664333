import {Navbar} from "../../../components/navbar/navbar";
import React, {useEffect, useState} from "react";

import './Commercial_user.scss'
import {Tabs} from "@mantine/core";
import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../../store/interfaceSlice";
import {IconeUsers} from "../../../components/icone/solid_react/users";
import {IconeMicrochip} from "../../../components/icone/solid_react/microchip";
import {IconeList} from "../../../components/icone/solid_react/list";
import {IconeUserTie} from "../../../components/icone/solid_react/user-tie";
import {useParams} from "react-router-dom";
import ajax from "../../../services/AxiosInterceptor";
import {Commercial, Resultat_calcul_commercial} from "../../../types/commercial";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {IconeReceipt} from "../../../components/icone/solid_react/receipt";
import {IconeSensor} from "../../../components/icone/solid_react/sensor";
import {Commercial_user_capteurs} from "./tabs/Commercial_user_capteurs";
import {Logs_viewer} from "../../../components/logs_viewer/logs_viewer";
import {Organisation} from "../../../types/organisation";
import {Capteur} from "../../../types/capteur";
import {afficherDate, DateTZ} from "../../../services/GestionDates";
import {Commercial_user_dashboard} from "./tabs/Commercial_user_dashboard";



export function Commercial_user() {

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("aucun"))

    const [activeTab, setActiveTab] = useState<string | null>('comm');

    let userId = useParams().userId;

    const [commercial, setCommercial] = useState<Commercial|null>(null);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [capteurs, setCapteurs] = useState<Capteur[] | null>(() => null);

    useEffect(() => {
        ajax.get("/commercial/" + userId).then(response => {
            setCommercial(response.data.commercial);
            let orgas:Organisation[] = response.data.organisations;
            setOrganisations(orgas);

            let capteurs:Capteur[] = response.data.capteurs;
            capteurs = capteurs.map(capteur => {
                capteur.organisation = orgas.find(o => o.id+"" == capteur.organisation_id+"") ?? null
                return capteur
            })
            setCapteurs(capteurs);
        }).catch(err => {})

    }, [userId]);



    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content content-fullwidth page_be"}>
                {!commercial ? (
                    <Loading_div />
                ) : (
                    <Tabs className={"Tabs"} value={activeTab} onChange={setActiveTab}>
                        <Tabs.List>
                            <Tabs.Tab value="comm" leftSection={<IconeReceipt />}>Commissions & paiements</Tabs.Tab>
                            <Tabs.Tab value="capteurs" leftSection={<IconeMicrochip />}>Enregistreurs</Tabs.Tab>
                            <Tabs.Tab value="logs" leftSection={<IconeList />}>Activités suivies</Tabs.Tab>
                            {/*<Tabs.Tab value="page5" icon={<Icone nom={"gear"} />}>Page 5</Tabs.Tab>*/}
                        </Tabs.List>

                        <Tabs.Panel value="comm" pt="xs">
                            {activeTab === "comm" && (
                                <Commercial_user_dashboard organisations={organisations} commercial={commercial} capteurs={capteurs} />
                            )}
                        </Tabs.Panel>

                        <Tabs.Panel value="capteurs" pt="xs">
                            {activeTab === "capteurs" && (
                                <>
                                    <Commercial_user_capteurs commercial={commercial} capteurs={capteurs} organisations={organisations} />
                                </>
                            )}
                        </Tabs.Panel>

                        <Tabs.Panel value="logs" pt="xs">
                            {activeTab === "logs" && (
                                <>
                                    <Logs_viewer api_query_url={'/commercial/'+userId+'/logs'} nbe_jour_load={90} mode_admin={true} />
                                </>
                                // <Utilisateurs_liste />
                            )}
                        </Tabs.Panel>
                    </Tabs>
                )}

            </div>
        </div>

    );
}
