import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Checkbox, Modal, NativeSelect, NumberInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation_paiement} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeMessageSms} from "../../../components/icone/solid_react/message-sms";
import {IconePhone} from "../../../components/icone/solid_react/phone";
import {PrixSMS} from "../../../types/codes_promo";
import {Loading_div} from "../../../components/loading_div/loading_div";
import {permissionIsGranted} from "../../../services/permissionsService";

export function Modale_credit_sms_tel({
                                          organisation_full, refresh
                                      }: { organisation_full: Organisation_paiement, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [prixSMS, setPrixSMS] = useState<PrixSMS[]|null>(null);
    const [prixTel, setPrixTel] = useState<PrixSMS[]|null>(null);

    const [TEL_a_recharger, setTEL_a_recharger] = useState<string | null>('0');
    const [SMS_a_recharger, setSMS_a_recharger] = useState<string | null>('0');

    const [valider_sans_payer, setValider_sans_payer] = useState(false);
    const [cgv, setCgv] = useState(false);

    // if (forcer) handlers.open();
    let organisation = organisation_full

    function handleEnregistrer() {
        set_save_btn_loading(true);
        ajax.post("/organisations/" + organisation.organisation.id + "/creditSMS", {
            "credit_SMS": Number(SMS_a_recharger),
            "credit_Tel": Number(TEL_a_recharger),
            "valider_sans_payer": valider_sans_payer,
        }).then(response => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Les crédits ont étés ajoutés à l\'organisation.',
                icon: <IconeCheck/>,

                // className: 'my-notification-class',
                loading: false,
            });
            handlers.close();
            refresh();
            set_save_btn_loading(false);
        }).catch(error => {
            set_save_btn_loading(false);
        })
    }

    useEffect(() => {
        ajax.get("/be/prixSMS").then(response => {
            setPrixSMS(response.data.prixSMS)
            setPrixTel(response.data.prixTel)
        }).catch(e => {
        })
    }, []);


    return (
        <>


            <Modal opened={opened} onClose={handlers.close} title="Recharger les SMS et Appels automatisés">

                <div className={"form"}>

                    <div className={"en-ligne"}>
                        <p className={"inline-tag"}>Actuellement, {organisation_full.organisation.credit_SMS} SMS
                            restant.</p>
                        <p className={"inline-tag"}>Actuellement, {organisation_full.organisation.credit_Tel} Appels
                            restant.</p>
                    </div>

                    {!prixSMS || !prixTel ? (
                        <Loading_div />
                        ) : (
                        <>
                            <NativeSelect
                                data={[{label: "Aucun SMS", value: "0"} , ...prixSMS.map(ligne => {
                                    return {label: ligne.nbe + " SMS pour " + ligne.prix + "€", value: ligne.nbe+""}
                                })]}
                                value={Number(SMS_a_recharger)}
                                onChange={e => setSMS_a_recharger(e.target.value + "")}
                                label={"Combien de SMS à ajouter à l'organisation ?"}
                                leftSection={<IconeMessageSms/>}
                            />
                            <NativeSelect
                                data={[{label: "Aucun appel", value: "0"} , ...prixTel.map(ligne => {
                                    return {label: ligne.nbe + " appels pour " + ligne.prix + "€", value: ligne.nbe+""}
                                })]}
                                value={Number(TEL_a_recharger)}
                                onChange={e => setTEL_a_recharger(e.target.value + "")}
                                label={"Combien d'appels automatisés à ajouter à l'organisation ?"}
                                leftSection={<IconePhone/>}
                            />
                            {/*<NumberInput value={Number(SMS_a_recharger)}*/}
                            {/*             onChange={e => setSMS_a_recharger(e + "")}*/}
                            {/*             label={"Combien de SMS à ajouter à l'organisation ?"}*/}
                            {/*             leftSection={<IconeMessageSms/>}*/}
                            {/*/>*/}
                            {/*<NumberInput value={Number(TEL_a_recharger)}*/}
                            {/*             onChange={e => setTEL_a_recharger(e + "")}*/}
                            {/*             label={"Combien d'appels automatisés à ajouter à l'organisation ?"}*/}
                            {/*             leftSection={<IconePhone/>}*/}
                            {/*/>*/}

                            <Checkbox
                                label={<>J'ai lu les <a href={env_config.url_cg_vente} target="_blank">conditions générales de
                                    ventes</a> et j'y adhère sans réserve.</>}
                                checked={cgv}
                                onChange={e => setCgv(e.target.checked)}
                            />

                            {permissionIsGranted(undefined, "ADMIN") && (
                                <Checkbox
                                    label={"ADMIN : Valider sans payer"}
                                    checked={valider_sans_payer}
                                    onChange={e => setValider_sans_payer(e.target.checked)}
                                />
                            )}




                            <Loading_button is_loading={save_btn_loading}
                                            onClick={handleEnregistrer} disabled={!cgv}>Valider la recharge</Loading_button>
                        </>
                    )}

                </div>

            </Modal>
            <button className={""} onClick={handlers.open}><IconeMessageSms/> Recharger les SMS et Appels</button>


        </>

    )
}