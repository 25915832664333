import React, {useEffect, useState} from "react";

import './Commerciaux_admin.scss'
import ajax from "../../../../services/AxiosInterceptor";
import {
    createColumnHelper, SortingState,
} from "@tanstack/react-table";
import {
    Accordion,
    Modal, NumberInput, Select,
    Textarea,
    TextInput,
    Tooltip
} from "@mantine/core";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Hub} from "../../../../types/hub";
import {Organisation, Organisation_light} from "../../../../types/organisation";
import {User} from "../../../../types/user";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {removeDuplicates, texte_est_inclus} from "../../../../services/GestionTextes";
import {getAffichageNom, getAffichageNomShort} from "../../../../services/UserService";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeClock} from "../../../../components/icone/solid_react/clock";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {useDisclosure} from "@mantine/hooks";
import {IconeEnvelope} from "../../../../components/icone/solid_react/envelope";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {permissionIsGranted} from "../../../../services/permissionsService";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {IconePlus} from "../../../../components/icone/solid_react/plus";
import {IconeReceipt} from "../../../../components/icone/solid_react/receipt";
import {afficherDate, afficherDateTime, DateTZ} from "../../../../services/GestionDates";
import {Commercial, Commercial_primes} from "../../../../types/commercial";
import {Statut_cellule} from "../../../../types/statut_cellule";
import {IconeTrash} from "../../../../components/icone/solid_react/trash";
import {Capteur} from "../../../../types/capteur";
import {IconeSensor} from "../../../../components/icone/solid_react/sensor";
import {IconeShop} from "../../../../components/icone/solid_react/shop";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {IconeTemperatureFull} from "../../../../components/icone/solid_react/temperature-full";
import {Modale_calcul_commercial} from "./modale_calcul_commercial";
import {IconeR} from "../../../../components/icone/solid_react/r";


export function Commerciaux_admin() {

    const [donnees, setDonnees] = useState<Commercial[] | null>(() => null);



    const [btn_loading, setBtn_loading] = useState(false);

    const [modaleLoadingData, setModaleLoadingData] = useState(false);


    const [modale_cellules_recherche, setModale_cellules_recherche] = React.useState<string>("")
    const [cellules_modale, setCellules_modale] = React.useState<Organisation[] | null>(null)

    function handleEnregistrerCommercial(commercial: Commercial) {
            setBtn_loading(true)
            ajax.put("/commercial/" + commercial.id, commercial).then(response => {
                setBtn_loading(false)
                if (donnees) setDonnees(donnees?.map(d => {
                    if (d.id === commercial.id) return commercial
                    return d
                }))
            }).catch(error => {
                setBtn_loading(false)
            });
    }

    function handleSupprimerCommercial(commercial: Commercial) {
        if (window.confirm("Vous êtes sur le point de supprimer le commercial " + (commercial.user ? getAffichageNom(commercial.user) + "(" + commercial.user.email + ")": "") )) {
            setBtn_loading(true)
            ajax.delete("/commercial/" + commercial.id).then(response => {
                setBtn_loading(false)
                if (donnees) setDonnees(donnees?.filter(d => d.id !== commercial.id))
            }).catch(error => {
                setBtn_loading(false)
            });
        }
    }

    const [titre, setTitre] = useState("");
    const [sujet, setSujet] = useState("");
    const [email_nouveau_commercial, setEmail_nouveau_commercial] = useState("");

    const [selection, setSelection] = useState<User[] | null>(null);

    function handle_creer_commercial() {
        setBtn_loading(true)
        ajax.post("/commercial ", {
            email: email_nouveau_commercial,
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Commercial créer!",
                message: '',
                icon: <IconeCheck/>,
            });

            handlers_creer_commercial.close()

            query_tableau();

            setBtn_loading(false)
        }).catch(err => {
            setBtn_loading(false)
        })
    }
    const [notesModal, setNotesModal] = useState<string|null>(null);
    const [modal_creer_commercial, handlers_creer_commercial] = useDisclosure(false);
    const [modal_editer_categorie_abonnement_openned, handlers_modal_editer_categorie_abonnement] = useDisclosure(false);

    const navigate = useNavigate();

    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)


    const Sonde_Cellule_Tableau_admin: typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "user.email", nom: "Email",noedit: true},
        {key: "user.nom_affichage", nom: "Prénom",noedit: true},
        {key: "user.nom_famille", nom: "Nom",noedit: true},
        {key: "user.tel", nom: "Tél",noedit: true},
        {key: "commissionVente", nom: "Comm vente", suffix: "%"},
        {key: "commissionAbonnement", nom: "Comm abonnements", suffix: "%"},
        {key: "commissionAnneePrecedente", nom: "Comm années précédentes", suffix: "%"},
        {
            key: "commissionsPrimes",
            nom: "Primes",
            wrapper: (primes: any[]) => (
                <>
                    {primes?.map((prime) => <p className={"inline-tag"}>{"> " + prime.plus_que_x_capteurs} capteurs : ven={prime.commissions.vente}%/abb={prime.commissions.abonnement}%/prec={prime.commissions.annee_precedentes}%/</p>)}
                </>
            ),
            edit_custom_input: (value:Commercial_primes[], onchange:(value:Commercial_primes[]) => void) => (
                // <Select
                //     data={status ? [
                //         {
                //             group: 'Affichable à l\'achat',
                //             items: status.filter(s => s.is_affichable_achat).map(s => s.nom + " ("+s.prix_achat+"€/mois)")
                //         },
                //         {
                //             group: 'Admin seulement',
                //             items: status.filter(s => !s.is_affichable_achat).map(s => s.nom + " ("+s.prix_achat+"€/mois)")
                //         },
                //     ] : []}
                //     label={"Statut"}
                //     // @ts-ignore
                //     value={value.nom + " ("+value.prix_achat+"€/mois)"}
                //     onChange={e => {
                //         onchange((status && status.find(s => s.nom + " ("+s.prix_achat+"€/mois)" === e)) ?? value)
                //     }}
                //     style={{marginTop: '0.5rem'}}
                // />
                <Commerciaux_admin_primes_input primes={value} setPrimes={onchange} />
            ),
        },
        {key: "notesAdmin", nom: "Notes admin", wrapper: (texte:string) => (
                <Tooltip label={texte} onClick={() => setNotesModal(texte)}>
                    <pre className={"inline-tag"}>{texte.length < 60 ? texte : texte.slice(0, 57) + "..."}</pre>
                </Tooltip>),
            spanClassname: "flex_align_center",
            type: "textarea"
        },
        {
            key: "paiementEffectues",
            nom: "Paiements effectués",
            wrapper: (paiements: any[]) => {
                let txt = "Paiements envoyés vers ce commercial : ";
                paiements.map(p => txt += "\n" + afficherDateTime(new DateTZ(p.date)) + " : " + p.montant + "€")
                return (
                    <>
                        <button onClick={() => setNotesModal(txt)}>
                            <IconeReceipt /> {paiements.length}
                        </button>
                        {paiements.length > 0 && (
                            <p>Dernier paiement le {afficherDate(paiements[paiements.length - 1].date)}</p>
                        )}

                    </>
                )
            },
            noedit: true,
        },
        {
            key: "cellulesSuivies",
            nom: "Cellules suivies",
            edit_display_value: (cellulesSuivies: number[]) => {
                return cellulesSuivies.length+""
            },
            noedit: true,
        },
        {
            key: "capteurs",
            nom: "Capteurs",
            wrapper: (capteurs: Capteur[]) => {
                let orgas:(number|undefined)[] = [];
                capteurs.forEach(capteur => {
                    if (!orgas.includes(capteur.organisation_id)){
                        orgas.push(capteur.organisation_id)
                    }
                })

                let texte_note:string = "";
                orgas.forEach(org => {
                    let orga_nom = organisations.find(o => o.id === org)?.nom;
                    if (!orga_nom) orga_nom = "Aucune organisation"
                    if (texte_note === "") {
                        texte_note += orga_nom + " : ";
                    } else {
                        texte_note += "\n-----\n" + orga_nom + " : ";
                    }

                    capteurs.forEach(capteur => {
                        if (capteur.organisation_id === org){
                            texte_note += "\n  - " + capteur.nom + " (" + capteur.uuid + ") " + (capteur.venteValide ? " -> Vendu" : "")
                        }
                    })
                })

                return <div onClick={() => setNotesModal(texte_note)}>
                    <p className={"inline-tag"}>{capteurs.filter(c => c.venteValide).length}/{capteurs.length}<IconeTemperatureFull/> </p>
                    <p className={"inline-tag"}>{orgas.length} <IconeShop/></p>
                </div>
            },
            noedit: true,
        },
        {
            key: "id", key_tab: "declencher_paiement",
            nom: "Options",
            wrapper: (id: number, commercial:Commercial) => {

                return (
                    <div className={"en-ligne"} style={{ flexDirection: "column" }}>
                        <button onClick={() => setModal_calcul_commercial(commercial)}><IconeReceipt /> Calculer commission</button>
                        <button onClick={() => navigate("/commercial/" + commercial.user?.id)}><IconeEye /> Voir la page</button>
                    </div>
                )
            },
            noedit: true,
        },


        {action_editer: true, cacherDansTableSi: (row) => false},
    ]

    const [modal_calcul_commercial, setModal_calcul_commercial] = useState<Commercial|null>(null);

    useEffect(() => {
        query_tableau();
    }, [])

    function query_tableau()
    {
        setDonnees(null);
        ajax.get("/commercial").then(response => setDonnees(response.data.commerciaux)).catch(e => {
        })
    }

    // const table = useReactTable<User>({
    //     data: donnees ? donnees : [],
    //     columns: columns,
    //     state: {
    //         sorting,
    //         columnVisibility,
    //     },
    //     onSortingChange: setSorting,
    //     onColumnVisibilityChange: setColumnVisibility,
    //     getCoreRowModel: getCoreRowModel(),
    //     getFilteredRowModel: getFilteredRowModel(),
    //     getSortedRowModel: getSortedRowModel(),
    //     getPaginationRowModel: getPaginationRowModel(),
    //     getFacetedRowModel: getFacetedRowModel(),
    //     getFacetedUniqueValues: getFacetedUniqueValues(),
    //     getFacetedMinMaxValues: getFacetedMinMaxValues(),
    //     enableFilters: true,
    //     enableColumnFilters: true
    // })


    return (
        <div className={"en-tete-pages"}>

            <div className={"en-ligne en-tete-page"}>

                <div className={"titre"}>
                    <h1>Commerciaux, vue administrateur</h1>
                </div>
                {selection && (
                    <div className={"flex-row"}>

                        {/*{permissionIsGranted(undefined, "ADMIN") && (*/}
                        {/*    <button onClick={handlers_creer_commercial.toggle}>*/}
                        {/*        <IconeEnvelope/>Ecrire un message Global*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*<button disabled={selection.length === 0}*/}
                        {/*        onClick={handlers_modal_editer_categorie_abonnement.toggle}>*/}
                        {/*    <IconeEnvelope/>Envoyer un email*/}
                        {/*</button>*/}

                        <button onClick={handlers_creer_commercial.toggle}>
                            <IconePlus/>Créer un commercial
                        </button>

                    </div>
                )}


            </div>



            <Tableau_helper
                typeDonnee={Sonde_Cellule_Tableau_admin}
                donnees={donnees}
                handleEnregistrer={handleEnregistrerCommercial}
                handleSupprimer={handleSupprimerCommercial}
                // formatNew={{
                //     id : -1,
                //     slug_type: "",
                //     affichage_nom_mesure: "",
                //     type_de_donnee: "number",
                //     categorie_de_sonde_id: "1",
                //     calcul: "",
                //     test_deconnexion: "",
                //     affichage_prefix: "",
                //     affichage_suffix: "",
                //     affichage_vrai: "",
                //     affichage_faux: "",
                // }}
                // boutons={<button onClick={() => {window.open("https://www.thinksrs.com/downloads/programs/therm%20calc/ntccalibrator/ntccalculator.html", '_blank');}}>Calculateur en ligne pour les coefficients NTC</button>}
                setTable={(_table) => {

                    setSelection(_table.getSelectedRowModel().rows.map((row) => row.original));
                }}
                enable_selection={true}
            />

            <Modal opened={cellules_modale !== null} onClose={() => {
                setCellules_modale(null)
            }} title="Alertes de l'utilisateur">
                <TextInput
                    placeholder={"Recherche organisation..."}
                    value={modale_cellules_recherche}
                    onChange={e => setModale_cellules_recherche(e.target.value)}
                />
                {(() => {
                    if (cellules_modale) {

                        return (
                            // eslint-disable-next-line react/jsx-no-undef
                            <Accordion chevronPosition="left" defaultValue="customization">
                                {cellules_modale.filter(o => texte_est_inclus(o.nom, modale_cellules_recherche)).map(orga => (
                                    <Accordion.Item value={orga.nom}>
                                        <Accordion.Control>{orga.nom}</Accordion.Control>
                                        <Accordion.Panel className={"accordion-flex-row"}>
                                            {orga.zones && orga.zones.map(zone =>
                                                (

                                                    <Accordion chevronPosition="left" defaultValue="customization">
                                                        <Accordion.Item
                                                            value={zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}>
                                                            <Accordion.Control>{zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}</Accordion.Control>
                                                            <Accordion.Panel className={"accordion-flex-row"}>
                                                                {zone.cellules && zone.cellules.map(cellule =>
                                                                    (
                                                                        <>
                                                                            {/*<p style={{width: "100%"}}>{cellule.nom}</p>*/}
                                                                            {cellule.conditions && cellule.conditions.map((condition: any) => { // :any => :Alerte avec :Condition dedans
                                                                                return (
                                                                                    <>
                                                                                        <Link
                                                                                            to={"/cellule/" + cellule.id}
                                                                                            className={"inline-tag"}>{cellule.nom} : {condition.condition.nom} {"->"} [{condition.canaux.toString()}]</Link>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </>


                                                                    ))
                                                                }
                                                            </Accordion.Panel>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                ))
                                            }
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )

                    }
                })()}
            </Modal>



            <Modal opened={modal_creer_commercial}
                   onClose={handlers_creer_commercial.close}
                   title="Créer un commercial">

                <p>Indiquez l'adresse email pour le compte du commercial. Si l'email appartient à un compte utilisateur existant, ce compte sera utilisé. Sinon, un nouveau compte sera créé.</p>

                <TextInput
                    label="Adresse email"
                    value={email_nouveau_commercial}
                    onChange={e => setEmail_nouveau_commercial(e.target.value)}
                    leftSection={<IconeEnvelope />}
                />


                <p>&nbsp;</p>
                <Loading_button is_loading={btn_loading} onClick={handle_creer_commercial}>
                    Valider
                </Loading_button>

            </Modal>
            <Modal title={"Notes"} opened={notesModal !== null} onClose={() => setNotesModal(null)}>
                <pre>{notesModal}</pre>

            </Modal>

            <Modale_calcul_commercial commercial={modal_calcul_commercial} close={() => setModal_calcul_commercial(null)} refresh={() => {
                setModal_calcul_commercial(null); query_tableau();
            }} />
        </div>

    );
}


export function Commerciaux_admin_primes_input({primes, setPrimes}:{primes: Commercial_primes[], setPrimes:(primes: Commercial_primes[]) => void}) {

    // const [primes, setPrimes] = useState<Commercial_primes[]>([...primes]);


    if (!primes) return (
        <p>Erreur.</p>
    );

    return (
        <>
            <p>Primes : </p>
            {primes.length === 0 ? (
                <p>Aucune prime</p>
            ) : (
                <div className={"primes-input"}>
                    {primes.map((prime, i) => (
                        <div className={"prime"}>
                            <div className={"en-ligne"} >
                                <NumberInput
                                    label={"+ que " + prime.plus_que_x_capteurs + " capteurs"}
                                    value={prime.plus_que_x_capteurs}
                                    onChange={e => setPrimes(primes.map((_p, _i) => {
                                        if (_i === i) {
                                            let edit = {..._p}
                                            edit.plus_que_x_capteurs = Number(e)
                                            return edit;
                                        }
                                        return _p;
                                    }))}
                                />
                                <button onClick={() => {
                                    setPrimes(primes.filter((d, _i) => i !== _i))
                                }}><IconeTrash />
                                </button>
                            </div>

                            <NumberInput
                                label={"Commission vente"}
                                value={prime.commissions.vente}
                                onChange={e => setPrimes(primes.map((_p, _i) => {
                                    if (_i === i){
                                        let edit = {..._p}
                                        edit.commissions.vente = Number(e)
                                        return edit;
                                    }
                                    return _p;
                                }) )}
                            />
                            <NumberInput
                                label={"Commission abonnements"}
                                value={prime.commissions.abonnement}
                                onChange={e => setPrimes(primes.map((_p, _i) => {
                                    if (_i === i){
                                        let edit = {..._p}
                                        edit.commissions.abonnement = Number(e)
                                        return edit;
                                    }
                                    return _p;
                                }) )}
                            />
                            <NumberInput
                                label={"Commission années précédentes"}
                                value={prime.commissions.annee_precedentes}
                                onChange={e => setPrimes(primes.map((_p, _i) => {
                                    if (_i === i){
                                        let edit = {..._p}
                                        edit.commissions.annee_precedentes = Number(e)
                                        return edit;
                                    }
                                    return _p;
                                }) )}
                            />
                        </div>

                    ))}
                </div>
            )}

            <button onClick={() => {
                setPrimes([...primes, {
                    "plus_que_x_capteurs": 0,
                    "commissions": {
                        "vente": 10,
                        "abonnement": 10,
                        "annee_precedentes": 10,
                    }
                }
                ]);
            }}>+ Ajouter une prime</button>

        </>

    )
}
