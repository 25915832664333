import React, {useEffect, useState} from "react";

import './Utilisateurs_liste.scss'
import ajax from "../../../../services/AxiosInterceptor";
import {
    createColumnHelper, SortingState,
} from "@tanstack/react-table";
import {
    Accordion,
    Modal,
    Textarea,
    TextInput,
    Tooltip
} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom";
import {Hub} from "../../../../types/hub";
import {Organisation, Organisation_light} from "../../../../types/organisation";
import {User} from "../../../../types/user";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {removeDuplicates, texte_est_inclus} from "../../../../services/GestionTextes";
import {getAffichageNom, getAffichageNomShort} from "../../../../services/UserService";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeClock} from "../../../../components/icone/solid_react/clock";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {useDisclosure} from "@mantine/hooks";
import {IconeEnvelope} from "../../../../components/icone/solid_react/envelope";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {permissionIsGranted} from "../../../../services/permissionsService";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";


export function Utilisateurs_liste() {

    const [donnees, setDonnees] = useState<User[] | null>(() => null);



    const [btn_loading, setBtn_loading] = useState(false);

    const columnHelper = createColumnHelper<User>()

    const [modaleLoadingData, setModaleLoadingData] = useState(false);


    const [modale_cellules_recherche, setModale_cellules_recherche] = React.useState<string>("")
    const [cellules_modale, setCellules_modale] = React.useState<Organisation[] | null>(null)


    function handleEnregistrerUser(user: User) {
        if (window.confirm("Vous êtes sur le point de modifier l'utilisateur " + getAffichageNom(user) + "(" + user.email + ")")) {
            setBtn_loading(true)
            ajax.put("/users/" + user.id, user).then(response => {
                setBtn_loading(false)
                if (donnees) setDonnees(donnees?.map(d => {
                    if (d.id === user.id) return user
                    return d
                }))
            }).catch(error => {
                setBtn_loading(false)
            });
        }
    }

    function handleSupprimerUser(user: User) {
        if (window.confirm("Vous êtes sur le point de supprimer l'utilisateur " + getAffichageNom(user) + "(" + user.email + ")")) {
            setBtn_loading(true)
            ajax.delete("/users/" + user.id).then(response => {
                setBtn_loading(false)
                if (donnees) setDonnees(donnees?.filter(d => d.id !== user.id))
            }).catch(error => {
                setBtn_loading(false)
            });
        }
    }

    function loadAlertesDeUnUser(user_id: number) {
        setModaleLoadingData(true)
        ajax.get("/utilisateurs/" + user_id + "/alertes").then(response => {
            setCellules_modale(response.data.alertes);
            setModaleLoadingData(false)
        }).catch(error => {

        })

        // setCellules_modale("texte")
    }

    const [titre, setTitre] = useState("");
    const [sujet, setSujet] = useState("");
    const [contenu, setContenu] = useState("");
    const [bouton_texte, setBouton_texte] = useState("Plateforme en ligne Freshtime");
    const [bouton_lien, setBouton_lien] = useState("https://app.freshtime.fr");

    const [selection, setSelection] = useState<User[] | null>(null);

    function envoyerEmail() {
        if (!selection) {
            alert("Table null")
            return null;
        }
        setBtn_loading(true)
        ajax.post("/app/envoi_emails", {
            utilisateurs: selection.map(u => u.id),
            contenu: contenu.replace(/\n/g, "<br />"),
            sujet: sujet,
            titre: titre,
            bouton_texte: bouton_texte,
            bouton_lien: bouton_lien,
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Emails envoyés!",
                message: '',
                icon: <IconeCheck/>,
            });

            setBtn_loading(false)
        }).catch(err => {
            setBtn_loading(false)
        })
    }

    function ecrire_message_global() {
        setBtn_loading(true)
        ajax.post("/app/ecrire_message_global", {
            contenu: contenu,
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Message validé!",
                message: '',
                icon: <IconeCheck/>,
            });

            setBtn_loading(false)
        }).catch(err => {
            setBtn_loading(false)
        })
    }

    const [modal_message_global_openned, handlers_message_global] = useDisclosure(false);
    const [modal_editer_categorie_abonnement_openned, handlers_modal_editer_categorie_abonnement] = useDisclosure(false);


    const Sonde_Cellule_Tableau_admin: typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {
            key: "statut", nom: "Statut",
            wrapper: (statut: string) => {
                let icone = <p>{statut}</p>;
                if (statut === "Actif") icone = <IconeCheck/>
                if (statut === "Non validé") icone = <IconeClock/>
                return (
                    <Tooltip label={"Statut : " + statut}>
                        <p>{icone}</p>
                    </Tooltip>
                )
            }
        },
        {key: "email", nom: "Email"},
        {
            key: "roles",
            nom: "Roles",
            wrapper: (roles: string[]) => (
                <>
                    {roles.filter(r => r !== "ROLE_USER").map(r => <span className={"inline-tag"}>{r}</span>)}
                </>
            ), type: "multi-select", select_data: [
                {"value": "ROLE_USER", "label": "ROLE_USER"},
                {"value": "ROLE_PRESTATAIRE", "label": "ROLE_PRESTATAIRE"},
                {"value": "ROLE_COMMERCIAL", "label": "ROLE_COMMERCIAL"},
                {"value": "ROLE_ADMIN", "label": "ROLE_ADMIN"},
            ]
        },
        {key: "nom_affichage", nom: "Prénom"},
        {key: "nom_famille", nom: "Nom"},
        {key: "tel", nom: "Tél"},
        {
            key: "organisations",
            nom: "Organisations",
            wrapper: (orgas: Organisation_light[]) => (
                <>
                    {orgas?.map((o) => <p className={"inline-tag"}>{o?.nom}</p>)}
                </>
            ),
            noedit: true,
        },
        {
            key: "timezone",
            nom: "Fuseau horaire",
            wrapper: (timezone: number | null) => timezone != null ? (
                <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                    {timezone}
                </span>
            ) : (
                <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                        Automatique
                </span>
            ),
            noedit: true,
        },
        {
            key: "id", key_tab: "alertes",
            nom: "Alertes",
            wrapper: (id: number, full_row: User) => (
                <Loading_button
                    is_loading={btn_loading}
                    onClick={() => loadAlertesDeUnUser(full_row.id)}
                ><IconeEye/> Alertes</Loading_button>
            ),
            noedit: true,
        },


        {action_editer: true, cacherDansTableSi: (row) => false},
    ]


    /*
    const columns = [
        columnHelper.accessor(row => row, {
            id: 'Selection',
            header: ({table}) => (
                <span className={"aligne_left"}>
                    {'\u00A0'}
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </span>

            ),
            cell: ({row}) => (
                <div className="px-1">
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                </div>
            ),

            enableColumnFilter: false,
            enableSorting: false
        }),
        columnHelper.accessor(row => row.id, {
            id: 'ID',
            header: () => 'ID',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>ID : </strong>
                        {info.getValue()}
                </span>
                )
        }),
        columnHelper.accessor('statut', {
            id: 'Statut',
            header: () => '',
            cell: info => {
                let icone = <p>{info.getValue()}</p>;
                if (info.getValue() === "Actif") icone = <IconeCheck/>
                if (info.getValue() === "Non validé") icone = <IconeClock/>
                return (<>
                        <strong className={"th_mobile"}>Statut : </strong>
                        <Tooltip label={"Statut : " + info.getValue()}>
                            <p>{icone}</p>
                        </Tooltip>

                    </>
                )
            },
            enableColumnFilter: false,
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.email, {
            id: 'Email',
            header: () => 'Email',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Email : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.roles, {
            id: 'Roles',
            header: () => 'Roles',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Roles : </strong>
                        {info.getValue().filter(r => r !== "ROLE_USER").map(r => <span className={"inline-tag"}>{r}</span>)}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.nom_affichage, {
            id: "Prenom",
            header: () => "Prénom",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Prénom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.nom_famille, {
            id: "Nom",
            header: () => "Nom",
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Nom : </strong>
                        {info.getValue()}
                </span>
                ),
            filterFn: texte_simple_compare_tableau,
        }),
        columnHelper.accessor(row => row.tel, {
            id: 'Tél',
            header: () => 'Tél',
            cell: info =>
                info.getValue() != null && (
                    <span>
                    <strong className={"th_mobile"}>Tél : </strong>
                        {info.getValue()}
                </span>
                ),
        }),
        columnHelper.accessor(row => JSON.stringify(row.organisations), {
            id: 'Orga',
            header: () => 'Organisations',
            cell: info =>
                info.getValue() != null && (
                    <>
                        <strong className={"th_mobile"}>Organisations : </strong>
                        {JSON.parse(info.getValue())?.map((o: Organisation_light) => <p
                            className={"inline-tag"}>{o?.nom}</p>)}
                    </>

                ),
        }),
        columnHelper.accessor(row => row.timezone, {
            id: 'fuseau_horaire',
            header: () => 'Fuseau horaire',
            cell: info =>
                info.getValue() != null ? (
                    <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                        {info.getValue()}
                </span>
                ) : (
                    <span>
                    <strong className={"th_mobile"}>Fuseau horaire : </strong>
                        Automatique
                </span>
                ),
        }),
        columnHelper.accessor(row => row, {
            id: 'Alertes',
            header: () => 'Alertes',
            cell: props => props.getValue() ? (
                <Loading_button
                    is_loading={btn_loading}
                    onClick={() => loadAlertesDeUnUser(props.getValue().id)}
                ><IconeEye/> Alertes</Loading_button>
            ) : (<></>),
            enableColumnFilter: false
        }),
        columnHelper.accessor(row => row, {
            id: 'Ajouter l\'enregistreur à l\'organisation selectionnée',
            header: () => 'Supprimer',
            cell: props => props.getValue() ? (
                <Loading_button
                    is_loading={btn_loading}
                    onClick={() => handleSupprimerUser(props.getValue())}
                ><IconeTrashCan/> Supprimer</Loading_button>
            ) : (<></>),
            enableColumnFilter: false
        }),
    ]
    */

    useEffect(() => {
        ajax.get("/users").then(response => setDonnees(response.data.users)).catch(e => {
        })
    }, [])

    // const table = useReactTable<User>({
    //     data: donnees ? donnees : [],
    //     columns: columns,
    //     state: {
    //         sorting,
    //         columnVisibility,
    //     },
    //     onSortingChange: setSorting,
    //     onColumnVisibilityChange: setColumnVisibility,
    //     getCoreRowModel: getCoreRowModel(),
    //     getFilteredRowModel: getFilteredRowModel(),
    //     getSortedRowModel: getSortedRowModel(),
    //     getPaginationRowModel: getPaginationRowModel(),
    //     getFacetedRowModel: getFacetedRowModel(),
    //     getFacetedUniqueValues: getFacetedUniqueValues(),
    //     getFacetedMinMaxValues: getFacetedMinMaxValues(),
    //     enableFilters: true,
    //     enableColumnFilters: true
    // })


    return (
        <div className={"en-tete-pages"}>

            <div className={"en-ligne en-tete-page"}>

                <div className={"titre"}>
                    <h1>Tous les utilisateurs de la plateforme</h1>
                </div>
                {selection && (
                    <div className={"flex-row"}>

                        {permissionIsGranted(undefined, "ADMIN") && (
                            <button onClick={handlers_message_global.toggle}>
                                <IconeEnvelope/>Ecrire un message Global
                            </button>
                        )}
                        <button disabled={selection.length === 0}
                                onClick={handlers_modal_editer_categorie_abonnement.toggle}>
                            <IconeEnvelope/>Envoyer un email
                        </button>

                        {/*<div>{selection.length} lignes affichés</div>*/}
                        {/*<NativeSelect*/}
                        {/*    className={"select_nbe_lignes match_height"}*/}
                        {/*    value={selection.getState().pagination.pageSize}*/}
                        {/*    onChange={e => {*/}
                        {/*        selection.setPageSize(Number(e.target.value))*/}
                        {/*    }}*/}
                        {/*    data={["5", "10", "20", "50", "100"]}*/}
                        {/*/>*/}
                    </div>
                )}


            </div>

            {/*<Tableau table={table} donnees={donnees}/>*/}


            <Tableau_helper
                typeDonnee={Sonde_Cellule_Tableau_admin}
                donnees={donnees}
                handleEnregistrer={handleEnregistrerUser}
                handleSupprimer={handleSupprimerUser}
                // formatNew={{
                //     id : -1,
                //     slug_type: "",
                //     affichage_nom_mesure: "",
                //     type_de_donnee: "number",
                //     categorie_de_sonde_id: "1",
                //     calcul: "",
                //     test_deconnexion: "",
                //     affichage_prefix: "",
                //     affichage_suffix: "",
                //     affichage_vrai: "",
                //     affichage_faux: "",
                // }}
                // boutons={<button onClick={() => {window.open("https://www.thinksrs.com/downloads/programs/therm%20calc/ntccalibrator/ntccalculator.html", '_blank');}}>Calculateur en ligne pour les coefficients NTC</button>}
                setTable={(_table) => {

                    setSelection(_table.getSelectedRowModel().rows.map((row) => row.original));
                }}
                enable_selection={true}
            />

            <Modal opened={cellules_modale !== null} onClose={() => {
                setCellules_modale(null)
            }} title="Alertes de l'utilisateur">
                <TextInput
                    placeholder={"Recherche organisation..."}
                    value={modale_cellules_recherche}
                    onChange={e => setModale_cellules_recherche(e.target.value)}
                />
                {(() => {
                    if (cellules_modale) {

                        return (
                            // eslint-disable-next-line react/jsx-no-undef
                            <Accordion chevronPosition="left" defaultValue="customization">
                                {cellules_modale.filter(o => texte_est_inclus(o.nom, modale_cellules_recherche)).map(orga => (
                                    <Accordion.Item value={orga.nom}>
                                        <Accordion.Control>{orga.nom}</Accordion.Control>
                                        <Accordion.Panel className={"accordion-flex-row"}>
                                            {orga.zones && orga.zones.map(zone =>
                                                (

                                                    <Accordion chevronPosition="left" defaultValue="customization">
                                                        <Accordion.Item
                                                            value={zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}>
                                                            <Accordion.Control>{zone.nom === "base.config.appareils" ? "Appareils" : zone.nom}</Accordion.Control>
                                                            <Accordion.Panel className={"accordion-flex-row"}>
                                                                {zone.cellules && zone.cellules.map(cellule =>
                                                                    (
                                                                        <>
                                                                            {/*<p style={{width: "100%"}}>{cellule.nom}</p>*/}
                                                                            {cellule.conditions && cellule.conditions.map((condition: any) => { // :any => :Alerte avec :Condition dedans
                                                                                return (
                                                                                    <>
                                                                                        <Link
                                                                                            to={"/cellule/" + cellule.id}
                                                                                            className={"inline-tag"}>{cellule.nom} : {condition.condition.nom} {"->"} [{condition.canaux.toString()}]</Link>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </>


                                                                    ))
                                                                }
                                                            </Accordion.Panel>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                ))
                                            }
                                        </Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )

                    }
                })()}
            </Modal>


            {donnees && selection && (
                <Modal opened={modal_editer_categorie_abonnement_openned}
                       onClose={handlers_modal_editer_categorie_abonnement.close}
                       title="Envoyer un email aux utilisateurs">

                    <p className={"en-ligne"}>
                        {selection.length} utilisateurs sélectionnés
                        : {selection.map((r, index) => {
                        if (index > 5) return (<></>)
                        return (<span className={"inline-tag"}>{getAffichageNomShort(r)}</span>)
                    })}
                        {selection.length > 5 && <span
                            className={"inline-tag"}>... ({selection.length - 6} autres)</span>}
                    </p>

                    <TextInput
                        label="Sujet"
                        description={"Sujet de l'email"}
                        value={sujet}
                        onChange={e => setSujet(e.target.value)}
                    />
                    <TextInput
                        label="Titre"
                        description={"Titre présent dans l'email"}
                        value={titre}
                        onChange={e => setTitre(e.target.value)}
                    />
                    <Textarea
                        label="Contenu de l'email"
                        description={"Contenu de l'email en HTML"}
                        value={contenu}
                        onChange={e => setContenu(e.target.value)}
                        autosize
                    />
                    <div className={"en-ligne"}>
                        {/*<a href="#" onClick={() => setContenu(contenu + "<br />")} ><IconeTurnDownLeft /></a>*/}
                        {/*<p>•</p>*/}
                        <a href="#" onClick={() => setContenu(contenu + "<strong></strong>")}>Gras</a>
                        <p>•</p>
                        <a href="#" onClick={() => setContenu(contenu + "<i></i>")}>Italique</a>
                    </div>
                    <TextInput
                        label="Texte du bouton"
                        value={bouton_texte}
                        onChange={e => setBouton_texte(e.target.value)}
                    />
                    <TextInput
                        label="Lien du bouton"
                        description={"Adresse URL sur laquelle le bouton envoi"}
                        value={bouton_lien}
                        onChange={e => setBouton_lien(e.target.value)}
                    />

                    <p>&nbsp;</p>
                    <button onClick={envoyerEmail}>
                        Valider
                    </button>

                </Modal>
            )}

            <Modal opened={modal_message_global_openned}
                   onClose={handlers_message_global.close}
                   title="Ecrire un message Global">

                <p>Les messages globaux sont les messages qui apparaissent pour tout les utilisateurs sur la plateforme,
                    dans le menu notification de la page d'accueil ainsi que dans leur page Notifications.</p>

                <Textarea
                    label="Contenu du message"
                    value={contenu}
                    onChange={e => setContenu(e.target.value)}
                    autosize
                />

                <p>&nbsp;</p>
                <button onClick={ecrire_message_global}>
                    Valider et écrire le message global
                </button>

            </Modal>

        </div>

    );
}

