import './Mot_de_passe_oublie.scss';
import React, {useEffect, useState} from "react";
import {Checkbox, PasswordInput, TextInput} from "@mantine/core";
import {IconeLoader} from "../../../../components/icone/icone";
import {Link, useParams} from "react-router-dom";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import ajax from "../../../../services/AxiosInterceptor";
import {IconeAt} from "../../../../components/icone/solid_react/at";
import {IconePhone} from "../../../../components/icone/solid_react/phone";
import {IconeMobile} from "../../../../components/icone/solid_react/mobile";
import {IconeMobileScreen} from "../../../../components/icone/solid_react/mobile-screen";
import env_config from "../../../../env_config";

export function Mot_de_passe_reset() {

    let token = useParams().token;

    const [mot_de_passe, set_mot_de_passe] = useState("");
    const [mot_de_passe_confirme, set_mot_de_passe_confirme] = useState("");

    const [token_valide, set_token_valide] = useState<boolean | null>(null);
    const [btn_loading, set_btn_loading] = useState(false);

    useEffect(() => {
        ajax.get("/resetpass/" + token).then(response => {
            set_token_valide(true)

        }).catch(error => {
            set_token_valide(false)
        })
    }, [])

    function handleValidation(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (mot_de_passe !== mot_de_passe_confirme) {
            alert("Les mots de passes ne correspondent pas.")
            return;
        }
        if (!(/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe)))
        {
            alert("Le mot de passe ne satisfait pas les conditions requises de sécurité.")
            return;
        }

        set_btn_loading(true)

        ajax.post("/resetpass/" + token, {
            "password" : mot_de_passe,
        }).then(response => {
            window.location.href = "/login?msg=resetpass";

            set_btn_loading(false)
        }).catch(error => {
            set_token_valide(false)
            set_btn_loading(false)
        })

    }

    return (
        <div className={"app inscription-token"}>
            <div className={"inscription_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>

                {!token_valide ? (
                        token_valide === null ? (
                                <div>
                                    <br/><br/>
                                    <p className={"en-ligne"}><IconeLoader /> Chargement de vos données, merci de
                                        patienter...</p>
                                    <br/><br/>
                                </div>
                        ) : (
                            <p className={"notice"}>Désolé, il semblerai que ce lien ai expiré. Merci de contacter votre organisation ou les équipes Freshtime.</p>
                        )
                ) : (
                    <form action="Auth/Inscription/Token#" onSubmit={handleValidation}>
                        <div className={"fullwidth"}>
                            <h3>Ré-initialisation de votre mot de passe</h3>

                            <p style={{fontSize: "var(--texte-size-petit)"}}>Merci de renseigner un nouveau mot de passe ci dessous : </p>
                        </div>

                        <label>
                            Mot de passe *
                            <PasswordInput
                                placeholder="Mot de passe"
                                required
                                value={mot_de_passe}
                                onChange={e => set_mot_de_passe(e.target.value)}
                                error={mot_de_passe === "" || /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe) ? null : true }
                            />
                        </label>
                        <label>
                            Confirmer le mot de passe *
                            <PasswordInput
                                placeholder="Mot de passe"
                                required
                                value={mot_de_passe_confirme}
                                error={mot_de_passe_confirme !== "" && mot_de_passe_confirme !== mot_de_passe ? true : null }
                                onChange={e => set_mot_de_passe_confirme(e.target.value)}
                            />
                        </label>

                        {!(mot_de_passe === "" || /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe)) && (
                            <p className={"notice red fullwidth"}>Le mot de passe doit contenir une lettre ET un chiffre, et faire au moins 8 caractères de long.</p>
                        )}
                        {mot_de_passe_confirme !== "" && mot_de_passe_confirme !== mot_de_passe && (
                            <p className={"notice red fullwidth"}>Les mot de passes ne correspondent pas</p>
                        )}


                        <Loading_button is_loading={btn_loading} onClick={() => {
                        }} className={"big"}>Valider le mot de passe</Loading_button>

                    </form>
                )}


            </div>
        </div>

    );
}
