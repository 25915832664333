import React, {useEffect, useState} from "react";

import ajax from "../../../../services/AxiosInterceptor";
import {Code, Modal, NumberInput} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {PrixSMS} from "../../../../types/codes_promo";
import {useDisclosure} from "@mantine/hooks";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeFloppyDisk} from "../../../../components/icone/solid_react/floppy-disk";
import {IconePlus} from "../../../../components/icone/solid_react/plus";
import {IconeMinus} from "../../../../components/icone/solid_react/minus";

export function PrixSMSAdmin() {

    const [SMS, setSMS] = useState<PrixSMS[] | null>(() => null);
    const [Tel, setTel] = useState<PrixSMS[] | null>(() => null);

    const [btn_loading, setBtn_loading] = useState(false);


    function handleEnregistrer() {

        setBtn_loading(true);
        ajax.put("/be/prixSMS", {
            "prixSMS": SMS,
            "prixTel": Tel,
        }).then((response: AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Les prix sont enregistrés',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setBtn_loading(false);
        }).catch(error => {
            setBtn_loading(false);
        });

    }

    useEffect(() => {
        ajax.get("/be/prixSMS").then(response => {
            setSMS(response.data.prixSMS)
            setTel(response.data.prixTel)
        }).catch(e => {
        })
    }, []);

    return (
        <>
            <div className={"en-ligne spacebetween"}>
                <h1>Prix des SMS et Appels automatisés</h1>
                <Loading_button style={{margin: "unset"}} is_loading={btn_loading}
                                onClick={handleEnregistrer}><IconeFloppyDisk/> Enregistrer</Loading_button>
            </div>

            <div className={"en-ligne margintop"}>
                <button style={{margin: "unset"}} onClick={() => {
                    if (!SMS) return;
                    setSMS([...SMS, {
                        nbe: 0, prix: 0
                    }])
                }}><IconePlus/></button>
                <h3>SMS : </h3>
            </div>
            <table width="100%" className={"margintop"}>
                <tr>
                    {/*<th>#</th>*/}
                    <th>Nombre</th>
                    <th>Prix</th>
                    <th>Supprimer</th>
                </tr>
                {SMS && SMS.map((ligne, index) => (

                    <tr>
                        {/*<td>#{index}</td>*/}
                        <td><NumberInput
                            value={ligne.nbe}
                            onChange={e => {
                                setSMS(SMS.map((ligne_edit, i_edit) => {
                                    if (i_edit === index) {
                                        let _edit: PrixSMS = {...ligne_edit}
                                        _edit.nbe = Number(e)
                                        return _edit;
                                    }
                                    return ligne_edit;
                                }))
                            }}
                        /></td>
                        <td><NumberInput
                            value={ligne.prix}
                            onChange={e => {
                                setSMS(SMS.map((ligne_edit, i_edit) => {
                                    if (i_edit === index) {
                                        let _edit: PrixSMS = {...ligne_edit}
                                        _edit.prix = Number(e)
                                        return _edit;
                                    }
                                    return ligne_edit;
                                }))
                            }}
                        /></td>
                        <td>
                            <button onClick={() => {
                                if (!SMS) return;
                                setSMS(SMS.filter((s, i) => i !== index))
                            }}><IconeMinus/></button>
                        </td>
                    </tr>
                ))}
            </table>

            <div className={"en-ligne margintop"}>
                <button style={{margin: "unset"}} onClick={() => {
                    if (!Tel) return;
                    setTel([...Tel, {
                        nbe: 0, prix: 0
                    }])
                }}><IconePlus/></button>
                <h3>Appels automatisés : </h3>
            </div>
            <table width="100%" className={"margintop"}>
                <tr>
                    {/*<th>#</th>*/}
                    <th>Nombre</th>
                    <th>Prix</th>
                    <th>Supprimer</th>
                </tr>
                {Tel && Tel.map((ligne, index) => (

                    <tr>
                        {/*<td>#{index}</td>*/}
                        <td><NumberInput
                            value={ligne.nbe}
                            onChange={e => {
                                setTel(Tel.map((ligne_edit, i_edit) => {
                                    if (i_edit === index) {
                                        let _edit: PrixSMS = {...ligne_edit}
                                        _edit.nbe = Number(e)
                                        return _edit;
                                    }
                                    return ligne_edit;
                                }))
                            }}
                        /></td>
                        <td><NumberInput
                            value={ligne.prix}
                            onChange={e => {
                                setTel(Tel.map((ligne_edit, i_edit) => {
                                    if (i_edit === index) {
                                        let _edit: PrixSMS = {...ligne_edit}
                                        _edit.prix = Number(e)
                                        return _edit;
                                    }
                                    return ligne_edit;
                                }))
                            }}
                        /></td>
                        <td>
                            <button onClick={() => {
                                if (!Tel) return;
                                setTel(Tel.filter((s, i) => i !== index))
                            }}><IconeMinus/></button>
                        </td>
                    </tr>
                ))}
            </table>


        </>


    );
}
