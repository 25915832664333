import React, {forwardRef, useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Avatar, Group, Modal, NativeSelect, NumberInput, Select, Switch, Textarea, TextInput} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {Capteur} from "../../../../types/capteur";
import {getAffichageNom} from "../../../../services/UserService";
import {Commercial} from "../../../../types/commercial";
import {DateInput} from "@mantine/dates";
import {DateTZ} from "../../../../services/GestionDates";
import {IconeEuroSign} from "../../../../components/icone/solid_react/euro-sign";
import {IconeCalendar} from "../../../../components/icone/solid_react/calendar";
import {IconeCalendarStar} from "../../../../components/icone/solid_react/calendar-star";
import {IconeUserTie} from "../../../../components/icone/solid_react/user-tie";
import {IconeStar} from "../../../../components/icone/solid_react/star";

export function Modale_editer_capteur({capteur, setEditedCapteur, capteurs, setCapteurs, commerciaux}: {
    capteur: Capteur | null,
    setEditedCapteur: (capteurs: Capteur | null) => void,
    capteurs: Capteur[] | null,
    setCapteurs: (capteurs: Capteur[]) => void
    commerciaux: Commercial[],
}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);


    const [tmp_capteur, setTmp_capteur] = useState<Capteur | null>(capteur);

    // const [commercial_id, setCommercial_id] = useState<string|null>((capteur?.commercial?.id ? capteur?.commercial?.id + "" : null) ?? null);

    function handleEnregistrer() {
        set_btn_loading(true);
        if (!tmp_capteur) {
            alert("Erreur, l'enregistreur est null.")
            return;
        }
        ajax.put("/capteurs/" + tmp_capteur.uuid, {
            "nom": tmp_capteur.nom,
            "notes": tmp_capteur.notes,
            "commercial_user_id": tmp_capteur.commercial?.id ?? null,
            "dateDebutRelationCommercial": tmp_capteur.dateDebutRelationCommercial ?? null,
            "dateDeVente": tmp_capteur.dateDeVente ?? null,
            "prixDeVente": tmp_capteur.prixDeVente,
            "venteValide": tmp_capteur.venteValide,
        }).then(response => {
            let capteurs_maj = capteurs?.map(h => {
                if (h.uuid === tmp_capteur.uuid) {
                    return tmp_capteur
                }
                return h;
            })
            if (capteurs_maj) {
                setCapteurs(capteurs_maj);
                handlers.close();
            }
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
        })

    }


    useEffect(() => {
        if (capteur) handlers.open()
        else handlers.close()
        setTmp_capteur(capteur)
        // setCommercial_id((capteur?.commercial?.id ? capteur?.commercial?.id + "" : null) ?? null)
    }, [capteur])


    if (!tmp_capteur) return <></>

    return (
        <>
            <Modal opened={opened} onClose={() => {
                handlers.close()
                setEditedCapteur(null);
            }} title={"Modifier l'enregistreur " + tmp_capteur.uuid}>
                <div className={"form"}>

                    <TextInput
                        label={"Nom"}
                        value={tmp_capteur.nom}
                        onChange={e => {
                            let _th = {...tmp_capteur};
                            _th.nom = e.target.value
                            setTmp_capteur(_th)
                        }}
                    />
                    <Textarea
                        label={"Notes"}
                        value={tmp_capteur.notes}
                        onChange={e => {
                            let _th = {...tmp_capteur};
                            _th.notes = e.target.value
                            setTmp_capteur(_th)
                        }}
                        autosize
                        minRows={2}
                    />

                    <Switch
                        label={"Cet appareil est lié à un commercial ?"}
                        checked={tmp_capteur.commercial !== null}
                        onChange={e => {
                            if (e.target.checked && commerciaux && commerciaux[0]?.user) {
                                let _th:Capteur = {...tmp_capteur};
                                _th.commercial = commerciaux[0].user
                                setTmp_capteur(_th)
                            } else {
                                let _th = {...tmp_capteur};
                                _th.commercial = null
                                _th.dateDebutRelationCommercial = null
                                setTmp_capteur(_th)
                            }
                        }}
                    />

                    {tmp_capteur.commercial !== null && (
                        <>
                            <NativeSelect
                                label="Commercial lié"
                                disabled={tmp_capteur.commercial === null}
                                data={commerciaux.map(commercial => {
                                    return {
                                        label: commercial.user ? getAffichageNom(commercial.user) : commercial.id + "",
                                        value: (commercial.user?.id ?? "0") + ""
                                    }
                                })}
                                value={tmp_capteur.commercial?.id ?? "Aucun"}
                                onChange={e => {
                                    let _th = {...tmp_capteur};
                                    _th.commercial = commerciaux.find(c => c.user?.id+"" === e.target.value)?.user ?? null;
                                    setTmp_capteur(_th)
                                }}
                                leftSection={<IconeUserTie />}
                            />
                            <DateInput
                                label="Début de la relation enregistreur-commercial"
                                locale="fr"
                                valueFormat="DD/MM/YYYY"
                                clearable
                                description="Si un commercial indique avoir vendu cet appareil sans y être lié, alors cette date sera identique à la date de vente."
                                value={tmp_capteur.dateDebutRelationCommercial ? new DateTZ(tmp_capteur.dateDebutRelationCommercial) : undefined}
                                onChange={e => {
                                    let _th:Capteur = {...tmp_capteur};
                                    _th.dateDebutRelationCommercial = e?.toISOString() ?? null;
                                    setTmp_capteur(_th)
                                }}
                                leftSection={<IconeCalendar />}
                            />
                        </>
                    )}




                    <DateInput
                        label="Date de vente"
                        locale="fr"
                        valueFormat="DD/MM/YYYY"
                        clearable
                        description="Date à laquelle l'appareil à été vendu à un client."
                        value={tmp_capteur.dateDeVente ? new DateTZ(tmp_capteur.dateDeVente) : undefined}
                        onChange={e => {
                            let _th:Capteur = {...tmp_capteur};
                            _th.dateDeVente = e?.toISOString() ?? null;
                            setTmp_capteur(_th)
                        }}
                        leftSection={<IconeCalendarStar />}
                    />

                    <NumberInput
                        label="Prix de vente"
                        description="Prix auquel l'appareil à été vendu au client. Utilisé pour calculé la commission du commercial."
                        value={tmp_capteur.prixDeVente}
                        onChange={e => {
                            let _th:Capteur = {...tmp_capteur};
                            _th.prixDeVente = Number(e);
                            setTmp_capteur(_th)
                        }}
                        leftSection={<IconeEuroSign />}
                    />

                    <Switch
                        label="Vente validé ?"
                        // disabled={!tmp_capteur.venteValide && !tmp_capteur.commercial}
                        checked={tmp_capteur.venteValide}
                        onChange={e => {
                            let _th:Capteur = {...tmp_capteur};
                            _th.venteValide = e.target.checked;
                            setTmp_capteur(_th)
                        }}
                    />

                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"check"}>Enregistrer</Loading_button>
                </div>

            </Modal>
        </>

    )
}