import React, {useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Checkbox, Modal, NumberInput, Select, Tabs, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation_paiement} from "../../../types/organisation";
import {afficherDate, DateTZ} from "../../../services/GestionDates";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeEuroSign} from "../../../components/icone/solid_react/euro-sign";
import {permissionIsGranted} from "../../../services/permissionsService";

export function Modale_recharger_balance({
                                             organisation_full, refresh
                                         }: { organisation_full: Organisation_paiement, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [montant_a_recharger, setMontant_a_recharger] = useState<string | null>('');

    const [cgv, setCgv] = useState(false);

    // if (forcer) handlers.open();
    let organisation:Organisation_paiement = {...organisation_full}

    function handleEnregistrer() {
        set_save_btn_loading(true);
        ajax.put("/organisations/" + organisation.organisation.id, {
            "balance": Number(montant_a_recharger)
        }).then(response => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'La somme à été ajouté à la balance de l\'organisation.',
                icon: <IconeCheck/>,

                // className: 'my-notification-class',
                loading: false,
            });
            handlers.close();
            refresh();
            set_save_btn_loading(false);
        }).catch(error => {
            set_save_btn_loading(false);
        })
    }

    if ((undefined === organisation.total
        || undefined === organisation.avance
        || undefined === organisation.remboursement
        || undefined === organisation.organisation.prochainPrelevement
        || undefined === organisation.rattrapage) && permissionIsGranted(undefined, "ADMIN"))
    {
        organisation.total = 0;
        organisation.avance = 0;
        organisation.rattrapage = 0;
        organisation.remboursement = 0;
        organisation.organisation.prochainPrelevement = new DateTZ().toISOString();
    }

    if (undefined === organisation.total
        || undefined === organisation.avance
        || undefined === organisation.remboursement
        || undefined === organisation.organisation.prochainPrelevement
        || undefined === organisation.rattrapage) {
        if (organisation_full.paiements && organisation_full.paiements[0]) {
            let dernier_paiement = organisation_full.paiements[0]
            organisation = {
                ...dernier_paiement.informations,
                enfants: [],
            }
            if (undefined === organisation.total
                || undefined === organisation.avance
                || undefined === organisation.remboursement
                || undefined === organisation.organisation.prochainPrelevement
                || undefined === organisation.rattrapage) {
                return <></>
            }
        } else return <></>
    }





    let nbe_mois_possible = -1;
    let balance_restant = organisation.organisation.balance;
    if (organisation.organisation.balance - organisation.total >= 0) {
        nbe_mois_possible = 0;
        balance_restant -= organisation.total
    }

    while (balance_restant >= organisation.avance) {
        if (organisation.avance <= 0)
        {
            // Sinon le while fait boucle infini
            nbe_mois_possible = 1200;
            break;
        }
        nbe_mois_possible++;
        // if (organisation.organisation.id==8)
        // alert(balance_restant + ">=" + organisation.avance)
        balance_restant -= organisation.avance;
    }



    let date_max = new DateTZ(organisation.organisation.prochainPrelevement)
    date_max.setMonth(
        new DateTZ(organisation.organisation.prochainPrelevement).getMonth() + nbe_mois_possible + 1)

    let dates_selectionnable_avec_valeur: { value: string, label: string }[] = [];
    if (organisation_full.organisation.etat === "Desac_paiement") {
        nbe_mois_possible++;
    }
    for (let i = nbe_mois_possible; i < 47; i++) {
        if ((i >= 0 && i <= 11) || i === 23 || i === 35) {
            let prixAvancesPourLesMoisNonPayes =  organisation.avance * (i - nbe_mois_possible);
            let prixPourAllerJusquaCetteDate = prixAvancesPourLesMoisNonPayes - balance_restant;
            prixPourAllerJusquaCetteDate = Math.round(prixPourAllerJusquaCetteDate*100)/100;
            if (prixPourAllerJusquaCetteDate > 0) {
                let date_ce_mois = new DateTZ(organisation.organisation.prochainPrelevement)
                date_ce_mois.setMonth(
                    new DateTZ(organisation.organisation.prochainPrelevement).getMonth() + i + 1)
                dates_selectionnable_avec_valeur.push(
                    {
                        value: '' + prixPourAllerJusquaCetteDate,
                        label: prixPourAllerJusquaCetteDate + "€ - Cellules actives jusqu'au "
                            + afficherDate(date_ce_mois)
                            // + date_ce_mois.toISOString()
                            + " (" + (i + 1) + " mois)"
                    }
                )
            }

        }

    }

    function clean_nbs(number: number)
    {
        return Math.round(number*100)/100;
    }


    return (
        <>


            <Modal opened={opened} onClose={handlers.close} title="Recharger la balance de l'organisation">
                {organisation.organisation.balance < organisation.total && organisation_full.organisation.etat === "Desac_paiement" && (
                    <div className={"paiement_modale_rechargement_intro_texte"}>
                        <p>Votre balance actuelle est insuffisante pour
                            régler la période courante.</p>
                        { organisation.rattrapage > 0 &&
                            <p>Pour la période précédente, vous devez rattraper {clean_nbs(organisation.rattrapage)}€</p>
                        }
                        {organisation.remboursement > 0 && <p>Sur la période précédente, la somme
                            de {clean_nbs(organisation.remboursement)}€ que vous aviez avancé n'a pas été entièrement dépensé et
                            est donc réutilisable de votre balance.</p>
                        }
                        <p>Vous devez au minimum rajouter {0 - clean_nbs(organisation.organisation.balance)}€ à votre balance pour
                            réactiver vos cellules.</p>
                    </div>

                )}
                {organisation.organisation.balance < organisation.total && organisation_full.organisation.etat !== "Desac_paiement" && (
                    <div className={"paiement_modale_rechargement_intro_texte"}>
                        <p>Votre balance actuelle est insuffisante pour
                            régler {clean_nbs(organisation.rattrapage) > 0 && "la période en cours et "}les avances
                            de la période à venir.</p>
                        {organisation.rattrapage > 0 &&
                            <p>Pour la période en cours, vous devez rattraper {clean_nbs(organisation.rattrapage)}€</p>}
                        {organisation.remboursement > 0 && <p>Pour la période en cours, la somme
                            de {clean_nbs(organisation.rattrapage)}€ que vous aviez avancé n'a pas été entièrement dépensé et
                            est donc réutilisable de votre balance.</p>}
                        <p>Votre balance est de {organisation.organisation.balance}€, et la somme necessaire pour
                            avancer la prochaine période est {clean_nbs(organisation.total)}€</p>
                        <p>Vous devez donc rajouter {clean_nbs(organisation.total - balance_restant)}€ à votre balance pour valider
                            la prochaine période.</p>
                    </div>

                )}
                {organisation.organisation.balance >= organisation.total && (
                    <div className={"paiement_modale_rechargement_intro_texte"}>
                        <p>Avec votre balance actuelle de {clean_nbs(organisation.organisation.balance)}€, la prochaine période
                            sera prélevé sans soucis.</p>
                        {organisation.avance <= 0 ? (
                            <>
                                Dans l'état actuel de vos configurations (0€), votre balance ne changera pas au prochain prélèvement.
                            </>
                        ) : (
                            <>
                                {organisation.organisation.balance >= organisation.total + organisation.avance && (
                                    <p>Votre balance actuelle permet de financer votre configuration actuelle de cellules
                                        jusqu'au {afficherDate(date_max)}, avec un reste
                                        de {Math.ceil(balance_restant * 100) / 100}€.
                                    </p>
                                )}
                            </>
                        )}
                    </div>

                )}


                <div className={"form"}>

                    <Tabs defaultValue="auto">
                        <Tabs.List>
                            <Tabs.Tab value="auto">Montants calculés</Tabs.Tab>
                            <Tabs.Tab value="libre">Montant libre</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="auto" pt="xs">
                            <Select data={dates_selectionnable_avec_valeur} value={montant_a_recharger}
                                    onChange={setMontant_a_recharger}
                                    label={"Quel montant souhaitez vous ajouter à votre organisation ?"}
                                    nothingFoundMessage="Aucun montant ne peut être calculé automatiquement pour votre organisation.
                                    Merci d'utiliser le montant libre si vous souhaitez tout de même recharger."
                            />
                        </Tabs.Panel>

                        <Tabs.Panel value="libre" pt="xs">
                            <NumberInput value={Number(montant_a_recharger)}
                                    onChange={e => setMontant_a_recharger(e + "")}
                                    label={"Quel montant souhaitez vous ajouter à votre organisation ?"}
                                         leftSection={<IconeEuroSign />}
                            />
                        </Tabs.Panel>
                    </Tabs>

                    <Checkbox
                        label={<>J'ai lu les <a href={env_config.url_cg_vente} target="_blank">conditions générales de ventes</a> et j'y adhère sans réserve.</>}
                        checked={cgv}
                        onChange={e => setCgv(e.target.checked)}
                    />

                    <Loading_button is_loading={save_btn_loading}
                                    onClick={handleEnregistrer} disabled={!cgv}>Valider la recharge</Loading_button>
                </div>

            </Modal>
            <button className={""} onClick={handlers.open}><IconeEuroSign/> Recharger la balance</button>


        </>

    )
}