import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Checkbox, Modal, NativeSelect, NumberInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Orga_enfant, Organisation_paiement} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeMessageSms} from "../../../components/icone/solid_react/message-sms";
import {IconePhone} from "../../../components/icone/solid_react/phone";

export function Modale_transferer_sms({
                                          organisation_parent, organisation_enfant, refresh
                                      }: { organisation_parent: Organisation_paiement, organisation_enfant: Orga_enfant, refresh: () => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [save_btn_loading, set_save_btn_loading] = useState(false);

    const [TEL_a_recharger, setTEL_a_recharger] = useState<string | null>('0');
    const [SMS_a_recharger, setSMS_a_recharger] = useState<string | null>('0');

    // if (forcer) handlers.open();
    let organisation = organisation_parent

    function handleEnregistrer() {
        set_save_btn_loading(true);
        ajax.post("/organisations/" + organisation.organisation.id + "/transfert_sms", {
            "enfant": organisation_enfant.id,
            "credit_SMS": Number(SMS_a_recharger),
            "credit_Tel": Number(TEL_a_recharger)
        }).then(response => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'Les crédits ont étés ajoutés à l\'organisation.',
                icon: <IconeCheck/>,

                // className: 'my-notification-class',
                loading: false,
            });
            handlers.close();
            refresh();
            set_save_btn_loading(false);
        }).catch(error => {
            set_save_btn_loading(false);
        })
    }

    return (
        <>


            <Modal opened={opened} onClose={handlers.close} title="Transferer les SMS et Appels automatisés">

                <div className={"form"}>
                    <h3>Parent : {organisation_parent.organisation.nom}</h3>
                    <div className={"en-ligne"}>
                        <p className={"inline-tag"}>{organisation_parent.organisation.credit_SMS} SMS
                            </p>
                        <p className={"inline-tag"}>{organisation_parent.organisation.credit_Tel} Appels
                            </p>
                    </div>
                    <h3>Enfant à créditer : {organisation_enfant.nom}</h3>
                    <div className={"en-ligne"}>
                        <p className={"inline-tag"}>{organisation_enfant.credit_SMS} SMS
                            </p>
                        <p className={"inline-tag"}>{organisation_enfant.credit_Tel} Appels
                            </p>
                    </div>

                    <p>Les transferts de SMS et d'appels se font depuis le crédit du parent vers les crédits des enfants.</p>


                    <NumberInput value={Number(SMS_a_recharger)}
                                 onChange={e => setSMS_a_recharger(e + "")}
                                 label={"Combien de SMS à envoyer vers "+organisation_enfant.nom+" ?"}
                                 leftSection={<IconeMessageSms/>}
                                 max={organisation_parent.organisation.credit_SMS}
                                 min={0 - organisation_enfant.credit_SMS}
                    />
                    <NumberInput value={Number(TEL_a_recharger)}
                                 onChange={e => setTEL_a_recharger(e + "")}
                                 label={"Combien d'appels automatisés à ajouter vers "+organisation_enfant.nom+" ?"}
                                 leftSection={<IconePhone/>}
                                 max={organisation_parent.organisation.credit_Tel}
                                 min={0 - organisation_enfant.credit_Tel}
                    />


                    <Loading_button is_loading={save_btn_loading}
                                    onClick={handleEnregistrer}>Valider le transfert</Loading_button>

                </div>

            </Modal>
            <a href="#" onClick={handlers.open} style={{color: "white", textDecoration: "underline"}}>Envoyer du crédit</a>

        </>

    )
}