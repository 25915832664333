import React, {forwardRef, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Avatar, Combobox, Group, Input, InputBase, Modal, TextInput, useCombobox} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation} from "../../../types/organisation";
import {IconeHexagonPlus} from "../../../components/icone/solid_react/hexagon-plus";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {useNavigate} from "react-router-dom";
import {notifications} from "@mantine/notifications";
import {setPasApasEtat} from "../../../store/pasApasSlice";
import {useDispatch} from "react-redux";

export function Modale_ajouter_appareil({handleValiderAppareil}:{handleValiderAppareil: (type_appareil: string|null, ref:string, nom:string)=>Promise<boolean>}) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [nom, setNom] = useState("");
    const [ref, setRef] = useState("");

    const [type_appareil, setType_appareil] = useState<string | null>(null);

    const form_ref = useRef<HTMLFormElement | null>(null);

    const navigate = useNavigate();

    // if (forcer) handlers.open();

    function handleEnregistrer() {
        if (!form_ref.current?.checkValidity()){
            form_ref.current?.reportValidity();
            return;
        }
        set_btn_loading(true);

        handleValiderAppareil(type_appareil, ref, nom).then((res) => {
            if (res) handlers.close();
            set_btn_loading(false);
        }).catch(e => {
            set_btn_loading(false);
        })


    }

    const data = [
        {
            image: '/logo192.png',
            label: 'Enregistreur Freshtime',
            value: 'Capteur-1',
            description: 'Freshtime Classic ou Freshtime Evolution',
            group: "Enregistreurs"
        },
        {
            image: '/logo192.png',
            label: 'Hub Freshtime',
            value: 'Hub-1',
            description: 'Permet la transmission des données entres les enregistreurs et le cloud',
            group: "Hubs"
        },
    ];

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        image: string;
        label: string;
        description: string;
    }

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ image, label, description, ...others }: ItemProps, ref) => (
            <div ref={ref} {...others}>
                <Group wrap={"nowrap"}>
                    <Avatar src={image} radius={"sm"} />

                    <div>
                        <p>{label}</p>
                        <p className={"description-custom-multi-select"}>{description}</p>
                    </div>
                </Group>
            </div>
        )
    );

    const dispatch = useDispatch();

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const selectedOption = data.find(d => d.value === type_appareil);

    return (
        <>
            <div className={"carte_mesure bleu white"} id={"btn-ajouter-appareil"}
                 onClick={() => {
                     handlers.open();
                     dispatch(setPasApasEtat("modale_ajouter_appareil_open"))
                 }}>
                <div className={"titre"}>
                    <div className={"titre_container"}>
                        <p className={"titre_texte"}>Ajouter un appareil</p>
                    </div>
                </div>
                <p className={"valeur"}><IconeHexagonPlus/></p>
            </div>


            <Modal opened={opened} onClose={handlers.close} title="Ajouter un appareil">
                <form className={"form"} ref={form_ref} onSubmit={event => event.preventDefault()}>

                        {/*<Select*/}
                        {/*    itemComponent={SelectItem}*/}
                        {/*    data={data}*/}
                        {/*    maxDropdownHeight={400}*/}
                        {/*    value={type_appareil}*/}
                        {/*    onChange={setType_appareil}*/}
                        {/*/>*/}
                        <Combobox
                            store={combobox}
                            withinPortal={false}
                            onOptionSubmit={(val) => {
                                setType_appareil(val);
                                combobox.closeDropdown();
                            }}
                        >
                            <Combobox.Target>
                                <InputBase
                                    component="button"
                                    type="button"
                                    label={"Quel type d'appareil voulez vous ajouter ?"}
                                    pointer
                                    rightSection={<Combobox.Chevron />}
                                    onClick={() => combobox.toggleDropdown()}
                                    rightSectionPointerEvents="none"
                                    multiline
                                >
                                    {selectedOption ? (
                                        // <SelectItem {...selectedOption} />
                                        selectedOption.label
                                    ) : (
                                        <Input.Placeholder>Merci d'indiquer le type d'appareil</Input.Placeholder>
                                    )}
                                </InputBase>
                            </Combobox.Target>

                            <Combobox.Dropdown>
                                <Combobox.Options>
                                    {Array.from(new Set(data.map(d => d.group))).map(group => (
                                            <Combobox.Group label={group} >
                                                {data.filter(d => d.group === group).map(item => (
                                                    <Combobox.Option value={item.value} key={item.value}>
                                                        <SelectItem {...item} />
                                                    </Combobox.Option>
                                                ))}
                                            </Combobox.Group>

                                        )
                                    )}

                                </Combobox.Options>
                            </Combobox.Dropdown>
                        </Combobox>



                    <TextInput type="text"
                               value={ref}
                               onChange={event => setRef(event.target.value)}
                               required={true}
                               label={"Entrez le numéro de série Freshtime de l'appareil :"}
                    />

                        <TextInput type="text"
                                   value={nom}
                                   onChange={event => setNom(event.target.value)}
                                   required={true}
                                   label={"Donnez un nom d'affichage à votre appareil :"}
                                   description={"Indiquez par exemple l'emplacement de votre appareil, ce nom vous permettra de différencier vos différents appareils autrement que par leur numéro de série"}
                        />




                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"magnifying-glass"}>Rechercher et ajouter</Loading_button>
                </form>

            </Modal>
        </>

    )
}