import './Estimation_cout_freshtime.scss';
import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Input,
    Mark,
    Menu, NativeSelect,
    NumberInput,
    Stepper,
    TextInput,
    Tooltip
} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";

import {IconeRight} from "../../../components/icone/solid_react/right";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import env_config from "../../../env_config";
import {IconeLeft} from "../../../components/icone/solid_react/left";
import {IconePlus} from "../../../components/icone/solid_react/plus";
import {IconeTemperatureFull} from "../../../components/icone/solid_react/temperature-full";
import {IconePlug} from "../../../components/icone/solid_react/plug";
import {IconeMinus} from "../../../components/icone/solid_react/minus";
import ajax from "../../../services/AxiosInterceptor";
import {abonnement_estimation_cout, Statut_cellule} from "../../../types/statut_cellule";
import {Regle_paiement, regles_par_defaut} from "../../../types/regle_paiement";
import {IconeCircleQuestion} from "../../../components/icone/solid_react/circle-question";
import image_classic from "./Freshtime Classic.png"
import image_evolution from "./Freshtime Evolution.png"
import image_banniere_new from "./new 2025 sens.png"
import {IconeUnderline} from "../../../components/icone/solid_react/underline";
import {notifications} from "@mantine/notifications";

export function Estimation_cout_freshtime() {

    const [stepperActive, setStepperActive] = useState(0);
    const nextStep = () => setStepperActive((current) => (current < 99 ? current + 1 : current));
    const prevStep = () => setStepperActive((current) => (current > 0 ? current - 1 : current));

    const [nbe_etages, set_nbe_etages] = useState(1);
    const [nbe_cellules, setNbe_cellules] = useState(1);
    const [wifi_dispo_partout, setWifi_dispo_partout] = useState(false);
    const [cellules_cote_a_cote, setCellules_cote_a_cote] = useState(0);

    type config_cellule = { nom: string, options: options_mesures_type[] }
    const [config_cellules, setConfig_cellules] = useState<config_cellule[]>([]);

    const [achat, setAchat] = useState(true);
    const [abonnement_classic_choisi, setAbonnement_classic_choisi] = useState<string>("");
    const [abonnement_evolution_choisi, setAbonnement_evolution_choisi] = useState<string>("");

    const [email, set_email] = useState("");

    const [veux_etre_contacter, setVeux_etre_contacter] = useState(false);

    const [btn_loading, set_btn_loading] = useState(false);

    const [nom, setNom] = useState("");
    const [nom_entreprise, setNom_entreprise] = useState("");
    const [tel, setTel] = useState("");

    const prix_classic = 70;
    const prix_evolution = 80;
    const prix_hub_classic = 480;
    const prix_hub_evolution = 520;

    type options_mesures_type = { "texte": string, "type": string }
    const options_mesures: options_mesures_type[] = [

        {texte: "Température ambiante interne", type: "blu-temp"},
        {texte: "Température à différents niveaux d’étagères (haut, milieu, bas)", type: "blu-temp"},
        {texte: "Température proche de la porte", type: "blu-temp"},
        {texte: "Température des parois internes", type: "blu-temp"},
        {texte: "Température extérieure proche du groupe frigorifique", type: "blu-temp"},


        {texte: "Température du bloc moteur", type: "temp"},
        {texte: "Température simulant la température produit", type: "temp"},
        {texte: "Température retour d’air dans l’évaporateur", type: "temp"},
        {texte: "Température de l’air soufflé à la sortie de l’évaporateur", type: "temp"},
        {texte: "Température de condensation", type: "temp"},
        {texte: "Température des serpentins de l’évaporateur", type: "temp"},
        {texte: "Température des produits sensibles", type: "temp"},

        {texte: "Manque de phase sur le compresseur", type: "cs"},
        {texte: "Groupe de froid en surchauffe", type: "cs"},
        {texte: "Manque de gaz", type: "cs"},
        {texte: "Alerte ouverture de porte", type: "cs"},
        {texte: "Alerte détection de mouvement", type: "cs"},
        {texte: "Alerte de personne enfermée dans une chambre froide", type: "cs"},
        {texte: "Détection de fumée", type: "cs"},
        {texte: "Détecteur d'inondation", type: "cs"},
        {texte: "Salle de réunion : occupée ou disponible", type: "cs"},
        {texte: "Station de recharge : en charge ou non", type: "cs"},
        {texte: "Détecteur de vibrations", type: "cs"},

    ]
    const options_mesures_defaut = options_mesures[0]

    type resultat_calcul = {
        capteur_classic: number
        capteur_evolution: number
        hub_classic: number
        hub_evolution: number
        cellules_classic: number
        cellules_evolution: number
        cellules_evolution_2e: number
        cellules_evolution_3e: number
        prix_achat: number
        prix_mensuel: number
        texte_remise_globale: string | null
    }
    const resultat_calcul_init: resultat_calcul = {
        capteur_classic: 0,
        capteur_evolution: 0,
        prix_achat: 0,
        prix_mensuel: 0,
        hub_evolution: 0,
        hub_classic: 0,
        cellules_classic: 0,
        cellules_evolution: 0,
        cellules_evolution_2e: 0,
        cellules_evolution_3e: 0,
        texte_remise_globale: null
    }
    const [resultat_calcul_mix, setResultat_calcul_mix] = useState<resultat_calcul>({...resultat_calcul_init});
    const [resultat_calcul_evo_pref, setResultat_calcul_evo_pref] = useState<resultat_calcul>({...resultat_calcul_init});
    const [resultat_calcul_user, setResultat_calcul_user] = useState<resultat_calcul>({...resultat_calcul_init});

    useEffect(() => {
        //Calcul du prix : mix
        let calculprix = (resultat: resultat_calcul): resultat_calcul => {


            if (!achat) {
                resultat.prix_achat = resultat.hub_classic * prix_hub_classic + resultat.hub_evolution * prix_hub_evolution;

            } else {

                resultat.prix_achat = resultat.capteur_evolution * prix_evolution + resultat.capteur_classic * prix_classic + resultat.hub_classic * prix_hub_classic + resultat.hub_evolution * prix_hub_evolution;

            }

            let abo_evo: abonnement_estimation_cout | undefined = abonnements.find(a => a.id + "" === abonnement_evolution_choisi)
            let abo_classic: abonnement_estimation_cout | undefined = abonnements.find(a => a.id + "" === abonnement_classic_choisi)
            if (!abo_evo || !abo_classic) {
                // alert("Aucun abonnement choisi ou trouvé. Impossible d'appliquer le calcul. Merci de contacter Freshtime.");
                resultat.prix_mensuel = -1;
                return resultat;
            }

            resultat.prix_mensuel = resultat.cellules_evolution * Number(abo_evo.prix_achat) + resultat.cellules_classic * Number(abo_classic.prix_achat);
            resultat.prix_mensuel += resultat.cellules_evolution_2e * (Number(abo_evo.prix_achat) * (100 - regles_paiement.deuxiemeCellule) / 100);
            resultat.prix_mensuel += resultat.cellules_evolution_3e * (Number(abo_evo.prix_achat) * (100 - regles_paiement.autre_cellules) / 100);

            let nbe_nombre_de_capteur = resultat.capteur_evolution + resultat.capteur_classic;
            if (nbe_nombre_de_capteur >= 30) {
                resultat.texte_remise_globale = "30 enregistreurs ou plus: remise globale de " + regles_paiement.plage_capteur30etPlus + "% (- " + Math.round(resultat.prix_mensuel * (regles_paiement.plage_capteur30etPlus / 100)) + "€ HT)"
                resultat.prix_mensuel *= (100 - regles_paiement.plage_capteur30etPlus) / 100;
            } else if (nbe_nombre_de_capteur >= 15) {
                resultat.texte_remise_globale = "Entre 15 et 29 enregistreurs actifs : remise globale de " + regles_paiement.plage_capteur15a29 + "% (- " + Math.round(resultat.prix_mensuel * (regles_paiement.plage_capteur15a29 / 100)) + "€ HT)"
                resultat.prix_mensuel *= (100 - regles_paiement.plage_capteur15a29) / 100;
            } else if (nbe_nombre_de_capteur >= 10) {
                resultat.texte_remise_globale = "Entre 10 et 14 enregistreurs actifs : remise globale de " + regles_paiement.plage_capteur10a14 + "% (- " + Math.round(resultat.prix_mensuel * (regles_paiement.plage_capteur10a14 / 100)) + "€ HT)"
                resultat.prix_mensuel *= (100 - regles_paiement.plage_capteur10a14) / 100;
            } else if (nbe_nombre_de_capteur >= 5) {
                resultat.texte_remise_globale = "Entre 5 et 9 enregistreurs actifs : remise globale de " + regles_paiement.plage_capteur5a9 + "% (- " + Math.round(resultat.prix_mensuel * (regles_paiement.plage_capteur5a9 / 100)) + "€ HT)"
                resultat.prix_mensuel *= (100 - regles_paiement.plage_capteur5a9) / 100;
            } else {
                resultat.texte_remise_globale = null;
            }

            resultat.prix_achat = Math.round(resultat.prix_achat * 100) / 100
            resultat.prix_mensuel = Math.round(resultat.prix_mensuel * 100) / 100

            return resultat;
        }

        let resultat_mix: resultat_calcul = {...resultat_calcul_init};
        config_cellules.map(cellule => {
            if (cellule.options.length === 0) return;
            let nbe_cellules_evo_oblige = cellule.options.filter(o => {
                return o.type !== "blu-temp"
            }).length;

            if (nbe_cellules_evo_oblige > 0) {
                resultat_mix.capteur_evolution++;
                resultat_mix.cellules_evolution++;
                if (cellule.options.length > 1) resultat_mix.cellules_evolution_2e++;
                if (cellule.options.length > 2) resultat_mix.cellules_evolution_3e += cellule.options.length - 2;
                return;
            } else {
                resultat_mix.capteur_classic += cellule.options.length;
                resultat_mix.cellules_classic += cellule.options.length;
                return;
            }
        })


        // alert (evolutions);

        resultat_mix.hub_classic = resultat_mix.capteur_classic > 0 ? nbe_etages : 0;
        resultat_mix.hub_evolution = resultat_mix.capteur_evolution > 0 && !wifi_dispo_partout ? 1 : 0;
        setResultat_calcul_mix(calculprix(resultat_mix));


        //Calcul prix tout en Evolutions
        let resultat_tout_evolution: resultat_calcul = {...resultat_calcul_init};
        let nbe_capteur_a_enlever_car_cote_a_cote = Math.ceil(cellules_cote_a_cote / 2)
        resultat_tout_evolution.capteur_evolution = config_cellules.length - nbe_capteur_a_enlever_car_cote_a_cote
        config_cellules.map((cellule) => {
            resultat_tout_evolution.cellules_evolution++;
            if (cellule.options.length > 1) resultat_tout_evolution.cellules_evolution_2e++;
            if (cellule.options.length > 2) resultat_tout_evolution.cellules_evolution_3e += cellule.options.length - 2;
        })
        if (nbe_capteur_a_enlever_car_cote_a_cote > 0 && resultat_tout_evolution.cellules_evolution_2e === 0) {
            resultat_tout_evolution.cellules_evolution -= nbe_capteur_a_enlever_car_cote_a_cote;
            resultat_tout_evolution.cellules_evolution_2e += nbe_capteur_a_enlever_car_cote_a_cote;
        } else {
            resultat_tout_evolution.cellules_evolution -= nbe_capteur_a_enlever_car_cote_a_cote;
            resultat_tout_evolution.cellules_evolution_3e += nbe_capteur_a_enlever_car_cote_a_cote;
        }

        resultat_tout_evolution.hub_classic = resultat_tout_evolution.capteur_classic > 0 ? nbe_etages : 0;
        resultat_tout_evolution.hub_evolution = resultat_tout_evolution.capteur_evolution > 0 && !wifi_dispo_partout ? 1 : 0;
        setResultat_calcul_evo_pref(calculprix(resultat_tout_evolution));


        // alert (evolutions);

        setResultat_calcul_user(calculprix(resultat_calcul_user));

    }, [config_cellules, wifi_dispo_partout, cellules_cote_a_cote, nbe_etages, abonnement_evolution_choisi, abonnement_classic_choisi, resultat_calcul_user]);


    useEffect(() => {
        // ajax.get("/inscription/token/" + token).then(response => {
        //     set_token_valide(true)
        //     set_nom_affichage("")
        //     set_nom_famille("")
        //     set_email(response.data.utilisateur.email)
        //     set_telephone(response.data.utilisateur.tel)
        //
        //     set_organisations(response.data.organisations)
        // }).catch(error => {
        //     set_token_valide(false)
        // })
    }, [])

    function handleValidation() {

        set_btn_loading(true)

        ajax.post("/app/estimation_couts/envoi_email", {
            "email": email,
            "recontacter": veux_etre_contacter,
            "nom": nom,
            "organisation": nom_entreprise,
            "tel": tel,
            "nbe_cellules_frigo": nbe_cellules,
            "nbe_etages": nbe_etages,
            "cellules_cote_a_cote": cellules_cote_a_cote,
            "wifi_couvre": wifi_dispo_partout,
            "config_cellules": config_cellules,
            "financement": achat,
            "abonnement_classic": abonnements.find(a => a.id + "" == abonnement_classic_choisi),
            "abonnement_evolution": abonnements.find(a => a.id + "" == abonnement_evolution_choisi),
            "estimation": resultat_calcul_user
        }).then(response => {
            // window.location.href = "/login?inscription=true";
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Email envoyé!",
                message: veux_etre_contacter ? 'Nous vous recontacterons au plus vite' : 'Merci de votre confiance',
                icon: <IconeCheck/>,
            });

            set_btn_loading(false)
            nextStep();
        }).catch(error => {
            set_btn_loading(false)
        })

    }


    function cellule_tooltip() {
        return (
            <>
                {" "}
                <Tooltip inline multiline label="Chambre froide, réfrigérateur, congélateur, banc frigorifique, etc."
                         events={{hover: true, focus: true, touch: true}}>
                    <Mark>Cellules frigorifiques</Mark>
                </Tooltip>
                {" "}
            </>
        )
    }

    function etages_tooltip() {
        return (
            <>
                <Tooltip inline multiline label="(Etages de l'établissement, RDC, 1er, etc)"
                         events={{hover: true, focus: true, touch: true}}>
                    <Mark>étages</Mark>
                </Tooltip>
                {" "}
            </>
        )
    }

    function contact_sec_tooltip() {
        return (
            <>{" "}
                <Tooltip inline multiline label="Alerte ouverture de porte , manque de gaz, etc"
                         events={{hover: true, focus: true, touch: true}}>
                    <Mark>contact sec</Mark>
                </Tooltip>
                {" "}
            </>
        )
    }

    function menu_item_type_en_icone(type: string) {
        switch (type) {
            case "temp":
                return <IconeTemperatureFull/>
            case "blu-temp":
                return <IconeTemperatureFull/>
            case "cs":
                return <IconePlug/>
        }
    }

    const [abonnements, setAbonnements] = useState<abonnement_estimation_cout[]>([]);
    const [regles_paiement, setRegles_paiement] = useState<Regle_paiement>(regles_par_defaut);

    useEffect(() => {
        ajax.get("/app/estimation_couts/abonnements").then(response => {
            setAbonnements(response.data.abonnements);
            setRegles_paiement(response.data.regles);
        }).catch(err => {

        })
    }, []);

    useEffect(() => {
        let _edit = []

        for (let i = 0; i < nbe_cellules; i++) {
            if (config_cellules[i] !== undefined) {
                _edit.push(config_cellules[i])
            } else {
                _edit.push({
                    nom: "", options: [options_mesures_defaut]
                });
            }

        }
        setConfig_cellules(_edit);
    }, [nbe_cellules]);

    useEffect(() => {
        setAbonnement_classic_choisi(abonnements.find(a => a.categorie_abonnement === "Classic acheté")?.id + "" ?? "")
        setAbonnement_evolution_choisi(abonnements.find(a => a.categorie_abonnement === "Evolution acheté")?.id + "" ?? "")
    }, [abonnements]);

    return (
        <div className={"app inscription"}>
            <div className={"inscription_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>


                <Stepper active={stepperActive} onStepClick={stepIndex => {
                    setStepperActive(stepIndex)
                }} allowNextStepsSelect={false} styles={{
                    stepBody: {
                        display: 'none',
                    },

                    step: {
                        display: 'none',
                    },
                }}>
                    <Stepper.Step label={""/*"Ajouter un appareil"*/}>
                        <div className={"stepper_inscription-page esti-cout"}>
                            <h3>Bienvenue sur l'estimateur de prix Freshtime.</h3>
                            <p>Cet outil est là pour vous aider à estimer le prix des services Freshtime, en prenant en
                                compte votre situation.</p>

                            <p style={{fontSize: "var(--texte-size-petit)"}}>
                                Cet outil vous donnera une estimation et non un devis final, car certaines situations
                                peuvent faire baisser ou augmenter le prix. Le calcul appliqué ici se base sur la
                                configuration la plus courante de nos clients. Les frais d'envoi et d'installation ne sont pas compris dans cette estimation.
                            </p>

                            <p>L'estimation prend 5 minutes, et une fois finie vous obtiendrez une estimation pour les
                                deux technologies Freshtime.</p>


                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <div>&nbsp;</div>
                                    <button className={""} style={{padding: "1rem"}}
                                            onClick={nextStep}>Commencer <IconeRight/>
                                    </button>
                                </div>
                            )}

                        </div>
                    </Stepper.Step>
                    <Stepper.Step label={""/*"Technologies"*/}>
                        <div className={"stepper_inscription-page esti-cout"}>
                            <p>Voici un rapide résumé des deux appareils, car ils sont complémentaires et conçus pour
                                répondre chacun à une situation différente : </p>

                            <p className={"notice green"}>Des promotions permanentes sont appliquées automatiquement sous certaines conditions, comme par exemple votre nombre d'appareil.
                                <button onClick={() => window.open("https://freshtime.fr/fr/tarif-freshtime", '_blank')}>En savoir +</button></p>

                            <div className={"en-ligne spacebetween esti-prix-grid-mobile"}
                                 style={{alignItems: "stretch", width: "100%"}}>

                                <div className={"esti_prix_carte"}>
                                    <div className={"en-ligne"}>

                                        <img className={"image_produit"} src={image_evolution} alt=""/>
                                        <img className={"image_produit"} src={image_banniere_new} alt=""/>
                                        <h3>Freshtime
                                            Evolution</h3>
                                    </div>
                                    <div className={"description"}>
                                        <p>
                                            <strong>8 entrées disponibles</strong> : Gérez jusqu'à 4 températures et 4
                                            contacts secs pour un suivi complet des cellules frigorifiques et de leurs
                                            équipements.
                                            <br/><i>Exemples : suivi de la température des condensateurs, ouvertures de
                                            portes, alertes de surchauffe, contrôle de la température ambiante.</i>
                                        </p>
                                        <p>
                                            <strong>Suivi des ouvertures de porte et analyses des thermostats</strong> :
                                            Détectez toute manipulation imprévue et anticipez les risques de surchauffe
                                            ou de pertes de gaz.
                                            <br/><i>Exemples : gestion des anomalies liées aux portes ouvertes,
                                            prévention des pannes de moteur.</i>
                                        </p>
                                        <p>
                                            <strong>Analyses intelligentes</strong> : Obtenez des alertes préventives et
                                            une interprétation approfondie pour éviter tout dysfonctionnement de vos
                                            chambres froides.
                                            <br/><i>Exemples : alertes sur les anomalies de température, diagnostics
                                            pour prévenir les pannes.</i>
                                        </p>
                                        <p>
                                            <strong>Grande portée et flexibilité</strong> : Couvrez jusqu’à 3 km en zone
                                            libre (700 m dans un bâtiment) avec un seul boîtier en Lora, ou économisez
                                            sur l'installation grâce à une connexion Wi-Fi pour les petites structures.
                                            <br/><i>Exemples : adapté aux grandes structures ou aux petites
                                            installations avec un bon signal Wi-Fi.</i>
                                        </p>
                                        <p>
                                            <strong>Installation et connectivité extensibles</strong> : L'enregistreur
                                            se place à l'extérieur des cellules frigorifiques et peut gérer jusqu'à 4
                                            sondes externes, à passer à l'intérieur de chaque cellule.
                                            <br/><i>Exemples : surveillance des différentes zones de stockage avec un
                                            seul appareil.</i>
                                        </p>
                                    </div>
                                </div>

                                <div className={"esti_prix_carte"}>
                                    <div className={"en-ligne"}>

                                        <img className={"image_produit"} src={image_classic} alt=""/>
                                        <h3>Freshtime
                                            Classic</h3>
                                    </div>
                                    <div className={"description"}>

                                        <p>
                                            <strong>Installation facile</strong> : S'installe directement dans la
                                            cellule frigorifique
                                            avec une seule sonde interne.
                                            <br/><i>Exemple : Suivi simple et rapide des températures sans complexité
                                            d'installation.</i>
                                        </p>
                                        <p>
                                            <strong>Connexion sans fil fiable</strong> : Signal RF sans fil couvrant
                                            jusqu'à 700 m en
                                            extérieur et 100 m en intérieur.
                                            <br/><i>Exemple : Idéal pour les petites et moyennes installations sans
                                            câblage
                                            compliqué.</i>
                                        </p>
                                        <p>
                                            <strong>Précision des relevés</strong> : Température mesurée avec une
                                            précision de ±0,1°C.
                                            <br/><i>Exemple : Suivi précis des températures pour respecter les normes
                                            d'hygiène et de sécurité.</i>
                                        </p>
                                        <p>
                                            <strong>Conception robuste et étanche</strong> : Certifié IP67, résistant à
                                            l'humidité et aux
                                            environnements difficiles.
                                            <br/><i>Exemple : Adapté aux conditions des chambres froides et autres
                                            environnements de travail exigeants.</i>
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <p>Cet outil va calculer vos besoins avec les deux technologies pour vous indiquer la
                                configuration la plus pertinente à votre situation</p>

                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Retour
                                    </button>
                                    <button className={""} style={{padding: "1rem"}}
                                            onClick={nextStep}>Continuer <IconeRight/>
                                    </button>
                                </div>
                            )}

                        </div>
                    </Stepper.Step>


                    <Stepper.Step label={""/*"Votre établissement"*/}>
                        <div className={"stepper_inscription-page esti-cout"}>
                            <p>
                                Nous avons besoin de connaitre la disposition des {cellule_tooltip()} de votre
                                organisation :
                            </p>

                            <NumberInput
                                label={<p>Combien de {cellule_tooltip()} avez vous ?</p>}
                                value={nbe_cellules}
                                onChange={e => {
                                    setNbe_cellules(Number(e))
                                    if (cellules_cote_a_cote > Number(e)) setCellules_cote_a_cote(Number(e))


                                }}
                                min={1}
                                max={999}
                            />
                            <NumberInput
                                label={<p>Sur combien d'{etages_tooltip()} sont réparties vos cellules ?</p>}
                                value={nbe_etages}
                                onChange={e => set_nbe_etages(Number(e))}
                                min={1}
                                max={99}
                            />

                            <NumberInput
                                label={<p>Combien de {cellule_tooltip()} sont côtes à côtes ?</p>}
                                description={<p style={{color: "#3e3e3e"}}>
                                    Les enregistreurs Freshtime Evolution peuvent gérer plusieurs cellules à<br/>
                                    la fois, à condition qu’elles soient proches pour connecter les sondes.</p>}
                                value={cellules_cote_a_cote}
                                onChange={e => setCellules_cote_a_cote(Number(e))}
                                min={0}
                                max={nbe_cellules}
                            />
                            {/*{cellules_cote_a_cote > 0 &&*/}
                            {/*    <p className={"inline-tag"}>Pour l'estimation réalisé ici, les cellules seront*/}
                            {/*        considérer comme des paires ensembles (deux par deux).</p>}*/}

                            <div className={"en-ligne"}>
                                <p>Votre réseau Wi-Fi couvre-t-il toutes les cellules frigorifiques ?</p>
                                <button onClick={() => setWifi_dispo_partout(true)}
                                        className={wifi_dispo_partout ? "green" : "sec"}>{wifi_dispo_partout &&
                                    <IconeCheck/>}Oui
                                </button>
                                <button onClick={() => setWifi_dispo_partout(false)}
                                        className={!wifi_dispo_partout ? "red" : "sec"}>{!wifi_dispo_partout &&
                                    <IconeCheck/>} Non
                                </button>
                            </div>

                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Retour
                                    </button>
                                    <button className={""} style={{padding: "1rem"}}
                                            onClick={nextStep}>Continuer <IconeRight/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Stepper.Step>


                    <Stepper.Step label={""/*"Options des cellules"*/}>
                        <div className={"stepper_inscription-page esti-cout"} style={{width: '100%'}}>
                            {nbe_cellules === 1 ? (
                                <>
                                    <p>
                                        Voici la cellule frigorifique que vous avez indiquée. Ici, indiquez si vous souhaitez ajouter un
                                        {contact_sec_tooltip()} et
                                        / ou une température supplémentaire en appuyant sur le <IconePlus
                                        className={"display-content"}/> :
                                    </p>
                                    <i style={{fontSize: "var(--texte-size-petit)"}}>(Pour retirer une mesure, cliquez
                                        sur le <strong style={{fontSize: "var(--texte-size-normal)"}}>-</strong> à sa
                                        droite)</i>
                                </>
                            ) : (
                                <>
                                    <p>
                                        Voici les {nbe_cellules} cellules frigorifiques que vous avez indiquées, une par
                                        ligne. Pour chacune, indiquez si vous souhaitez ajouter un
                                        {contact_sec_tooltip()} et
                                        / ou une température supplémentaire en appuyant sur le <IconePlus
                                        className={"display-content"}/> :
                                    </p>
                                    <i style={{fontSize: "var(--texte-size-petit)"}}>(Pour retirer une mesure, cliquez
                                        sur le <IconeMinus className={"display-content"}/> à sa gauche)</i>
                                </>
                            )}


                            <table style={{width: '100%'}}>
                                <thead className={"hide_on_mobile"}>
                                <tr>
                                    <th style={{textAlign: "left"}}>
                                        <div className={"en-ligne"}>
                                            Nom de la cellule
                                            <Tooltip multiline
                                                     events={{hover: true, focus: true, touch: true}}

                                                     label={
                                                         <p>Indiquez le nom de la cellule frigorifique pour une
                                                             identification plus facile (optionnel)</p>
                                                     }>
                                                <i><IconeCircleQuestion/></i>
                                            </Tooltip>
                                        </div>

                                    </th>
                                    <th style={{textAlign: "left", paddingLeft: "1rem"}}>
                                        <div className={"en-ligne"}>
                                            Mesures
                                            <Tooltip multiline
                                                     events={{hover: true, focus: true, touch: true}}
                                                     label={
                                                         <p>Sélectionnez les mesures supplémentaires souhaitées pour
                                                             votre cellule frigorifique : température ambiante interne
                                                             par défaut, ou ajoutez d'autres capteurs de température ou
                                                             contacts secs si nécessaire.</p>
                                                     }>
                                                <i>{" "}<IconeCircleQuestion/></i>
                                            </Tooltip>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {config_cellules.map((cellule, index) => (
                                    <tr className={"tr-ligne-cellule"}>
                                        <td><TextInput
                                            placeholder={"Nom de la cellule ?"}
                                            value={cellule.nom}
                                            onChange={e => {
                                                setConfig_cellules(config_cellules.map((c, i) => {
                                                    if (index === i) return {
                                                        nom: e.target.value,
                                                        options: c.options
                                                    }
                                                    return c;
                                                }))
                                            }}
                                        /></td>
                                        <td className={"en-ligne"} style={{paddingLeft: "1rem"}}>
                                            {cellule.options.map((option, option_index) => (
                                                <div className={"inline-tag tr-esti-fw"} style={{flexWrap: "nowrap"}}>
                                                    <span style={{cursor: "pointer"}} onClick={() => {
                                                        setConfig_cellules(config_cellules.map((c, i) => {
                                                            if (index === i) return {
                                                                nom: c.nom,
                                                                options: c.options.filter((co, coi) => coi !== option_index)
                                                            }
                                                            return c;
                                                        }))
                                                    }}><IconeMinus/></span>
                                                    {option.texte}

                                                </div>
                                            ))}
                                            <Menu shadow="md" width={200} portalProps={{
                                                className: "portal_menu_max_height"
                                            }}>
                                                <Menu.Target>
                                                    <div className={"inline-tag green"} style={{cursor: "pointer"}}>
                                                        <IconePlus/>
                                                    </div>
                                                </Menu.Target>

                                                <Menu.Dropdown>
                                                    <Menu.Label>Mesures communes</Menu.Label>
                                                    {options_mesures.filter(o => {
                                                        let valide = true;
                                                        if (cellule.options.filter(a => a.type === "temp" || a.type === "blu-temp").length >= 4) {
                                                            valide = o.type !== "temp" && o.type !== "blu-temp"
                                                        }
                                                        if (cellule.options.filter(a => a.type === "cs").length >= 4) {
                                                            valide = o.type !== "cs" && valide
                                                        }
                                                        return valide
                                                    }).map(option => (
                                                        <Menu.Item
                                                            leftSection={
                                                                menu_item_type_en_icone(option.type)
                                                            }
                                                            onClick={() => {
                                                                setConfig_cellules(config_cellules.map((c, i) => {
                                                                    if (index === i) return {
                                                                        nom: c.nom,
                                                                        options: [...c.options, option]
                                                                    }
                                                                    return c;
                                                                }))
                                                            }}
                                                        >
                                                            {option.texte}
                                                        </Menu.Item>
                                                    ))}

                                                </Menu.Dropdown>
                                            </Menu>

                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </table>


                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Retour
                                    </button>
                                    <button className={""} style={{padding: "1rem"}}
                                            onClick={nextStep}>Continuer <IconeRight/>
                                    </button>
                                </div>
                            )}
                        </div>
                    </Stepper.Step>

                    <Stepper.Step label={""/*"Estimation du prix"*/}>
                        <div className={"stepper_inscription-page esti-cout"} style={{width: '100%'}}>

                            <div className={"en-ligne"}>
                                <p>Financement de l'équipement : </p>
                                <button onClick={() => {
                                    setAchat(true);
                                    setAbonnement_classic_choisi(abonnements.find(a => a.categorie_abonnement === "Classic acheté")?.id + "" ?? "")
                                    setAbonnement_evolution_choisi(abonnements.find(a => a.categorie_abonnement === "Evolution acheté")?.id + "" ?? "")
                                }}
                                        className={achat ? "green" : "sec"}>{achat &&
                                    <IconeCheck/>}Achat + abonnement
                                </button>
                                <button onClick={() => {
                                    setAchat(false);
                                    setAbonnement_classic_choisi(abonnements.find(a => a.categorie_abonnement === "Classic loué")?.id + "" ?? "")
                                    setAbonnement_evolution_choisi(abonnements.find(a => a.categorie_abonnement === "Evolution loué")?.id + "" ?? "")
                                }}
                                        className={!achat ? "green" : "sec"}>{!achat &&
                                    <IconeCheck/>} Location + abonnement
                                </button>
                            </div>


                            <div className={"en-ligne"}>
                                <NativeSelect
                                    label={"Quel abonnement souhaitez vous pour les Classic ?"}
                                    value={abonnement_classic_choisi}
                                    onChange={(event) => setAbonnement_classic_choisi(event.currentTarget.value)}
                                    data={
                                        abonnements
                                            .filter(a => {
                                                if (achat) return a.categorie_abonnement === "Classic acheté"
                                                else return a.categorie_abonnement === "Classic loué"

                                            })
                                            .map(abonnement => {
                                                return {
                                                    value: abonnement.id + "",
                                                    label: abonnement.nom + " (" + abonnement.prix_achat + "€ HT/mois/cellule)"
                                                }
                                            })

                                    }
                                />
                                <NativeSelect
                                    label={"Quel abonnement souhaitez vous pour les Evolution ?"}
                                    value={abonnement_evolution_choisi}
                                    onChange={(event) => setAbonnement_evolution_choisi(event.currentTarget.value)}
                                    data={
                                        abonnements
                                            .filter(a => {
                                                if (achat) return a.categorie_abonnement === "Evolution acheté"
                                                else return a.categorie_abonnement === "Evolution loué"

                                            })
                                            .map(abonnement => {
                                                return {
                                                    value: abonnement.id + "",
                                                    label: abonnement.nom + " (" + abonnement.prix_achat + "€ HT/mois/cellule)"
                                                }
                                            })

                                    }
                                />
                            </div>

                            {JSON.stringify(resultat_calcul_mix) == JSON.stringify(resultat_calcul_evo_pref) ? (
                                <h2>Estimation des prix : </h2>
                            ) : (
                                <h2>Deux options sont disponibles : </h2>
                            )}


                            <div className={"en-ligne spacebetween wrapper-cartes-esti-prix"}
                                 style={{alignItems: "stretch", width: "100%"}}>
                                {((JSON.stringify(resultat_calcul_mix) == JSON.stringify(resultat_calcul_evo_pref)
                                            && resultat_calcul_mix.capteur_classic > 0)
                                        || JSON.stringify(resultat_calcul_mix) != JSON.stringify(resultat_calcul_evo_pref))
                                    && (
                                        <div className={"esti_prix_carte"}>
                                            {resultat_calcul_mix.capteur_evolution > 0 ? (
                                                <h3>Combinaison des deux technologies suivant vos besoins: </h3>
                                            ) : (
                                                <h3>Uniquement en technologie Classic: </h3>
                                            )}

                                            {resultat_calcul_mix.capteur_classic > 0 && achat && (
                                                <p>{" - "}Achat de {resultat_calcul_mix.capteur_classic} Classic
                                                    ({prix_classic}€ HT
                                                    unitaire) </p>
                                            )}
                                            {resultat_calcul_mix.hub_classic > 0 && (
                                                <p>{" - "}Achat
                                                    de {resultat_calcul_mix.hub_classic} Hub{resultat_calcul_mix.hub_classic > 1 && "s"} Classic
                                                    ({prix_hub_classic}€ HT
                                                    unitaire)</p>
                                            )}

                                            {resultat_calcul_mix.capteur_evolution > 0 && achat && (
                                                <p>{" - "}Achat de {resultat_calcul_mix.capteur_evolution} Evolution
                                                    ({prix_evolution}€ HT
                                                    unitaire)</p>
                                            )}
                                            {resultat_calcul_mix.hub_evolution > 0 && (
                                                <p>{" - "}Achat
                                                    de {resultat_calcul_mix.hub_evolution} Hub{resultat_calcul_mix.hub_evolution > 1 && "s"} Evolution
                                                    ({prix_hub_evolution}€ HT
                                                    unitaire) </p>
                                            )}

                                            {resultat_calcul_mix.texte_remise_globale && (
                                                <p>{resultat_calcul_mix.texte_remise_globale}</p>
                                            )}

                                            {resultat_calcul_mix.capteur_evolution > resultat_calcul_mix.cellules_evolution_2e && (
                                                <p className={"en-ligne"} style={{marginTop: "auto"}}>
                                                    {resultat_calcul_mix.capteur_evolution - resultat_calcul_mix.cellules_evolution_2e === 1 && (
                                                        <>+{resultat_calcul_mix.capteur_evolution - resultat_calcul_mix.cellules_evolution_2e} mesure
                                                            secondaire gratuite non utilisée</>
                                                    )}
                                                    {resultat_calcul_mix.capteur_evolution - resultat_calcul_mix.cellules_evolution_2e > 1 && (
                                                        <>+{resultat_calcul_mix.capteur_evolution - resultat_calcul_mix.cellules_evolution_2e} mesures
                                                            secondaires gratuites non utilisées</>
                                                    )}
                                                    <Tooltip multiline
                                                             events={{hover: true, focus: true, touch: true}}
                                                             label={<p>L'abonnement de chaque 2e mesure d'un
                                                                 enregistreur est offerte</p>

                                                             }>
                                                        <span><IconeCircleQuestion/></span>
                                                    </Tooltip>
                                                </p>
                                            )}

                                            <div className={"en-ligne spacebetween"}
                                                 style={{gap: "0.5rem", marginTop: "auto"}}>
                                                <strong style={{}}>Coût à l'achat
                                                    : {resultat_calcul_mix.prix_achat}€ HT</strong>
                                                <strong className={"en-ligne"}>
                                                    Coût mensuel : {resultat_calcul_mix.prix_mensuel}€ HT/mois
                                                    <Tooltip multiline
                                                             events={{hover: true, focus: true, touch: true}}
                                                             label={
                                                                 <p>{resultat_calcul_mix.cellules_classic} cellules
                                                                     virtuelles
                                                                     Classic
                                                                     <br/>
                                                                     {resultat_calcul_mix.cellules_evolution} cellules
                                                                     virtuelles
                                                                     Evolution
                                                                     {resultat_calcul_mix.cellules_evolution_2e > 0 && (
                                                                         <>
                                                                             <br/>
                                                                             {resultat_calcul_mix.cellules_evolution_2e} cellules
                                                                             virtuelles
                                                                             Evolution (2ème de l'enregistreur, remise
                                                                             de {regles_paiement.deuxiemeCellule}%)
                                                                         </>
                                                                     )}
                                                                     {resultat_calcul_mix.cellules_evolution_3e > 0 && (
                                                                         <>
                                                                             <br/>
                                                                             {resultat_calcul_mix.cellules_evolution_3e} cellules
                                                                             virtuelles
                                                                             Evolution (3ème et + de l'enregistreur,
                                                                             remise
                                                                             de {regles_paiement.autre_cellules}%)
                                                                         </>
                                                                     )}
                                                                 </p>
                                                             }>
                                                        <span><IconeCircleQuestion/></span>
                                                    </Tooltip>
                                                </strong>
                                            </div>

                                            <button onClick={() => {
                                                setResultat_calcul_user({...resultat_calcul_mix});
                                                nextStep();
                                            }}>Cette proposition me convient!
                                            </button>
                                        </div>
                                    )
                                }

                                {((JSON.stringify(resultat_calcul_mix) == JSON.stringify(resultat_calcul_evo_pref)
                                            && resultat_calcul_mix.capteur_classic === 0)
                                        || JSON.stringify(resultat_calcul_mix) != JSON.stringify(resultat_calcul_evo_pref))
                                    && (
                                        <div className={"esti_prix_carte"}>
                                            <h3>Uniquement en technologie Evolution: </h3>
                                            {achat && (
                                                <p>{" - "}Achat
                                                    de {resultat_calcul_evo_pref.capteur_evolution} Evolution
                                                    ({prix_evolution}€ HT
                                                    unitaire)</p>
                                            )}

                                            {resultat_calcul_evo_pref.hub_evolution > 0 && (
                                                <p>{" - "}Achat
                                                    de {resultat_calcul_evo_pref.hub_evolution} Hub{resultat_calcul_evo_pref.hub_evolution > 1 && "s"} Evolution
                                                    ({prix_hub_evolution}€ HT unitaire)</p>
                                            )}


                                            {cellules_cote_a_cote > 1 && (
                                                <p>{cellules_cote_a_cote} cellules cotes-a-cotes, ce qui permet de
                                                    d'utiliser
                                                    uniquement {Math.floor(cellules_cote_a_cote / 2)} enregistreur pour
                                                    les
                                                    couvrir</p>
                                            )}
                                            {resultat_calcul_evo_pref.texte_remise_globale && (
                                                <p>{resultat_calcul_evo_pref.texte_remise_globale}</p>
                                            )}
                                            {resultat_calcul_evo_pref.capteur_evolution > resultat_calcul_evo_pref.cellules_evolution_2e && (
                                                <p className={"en-ligne"} style={{marginTop: "auto"}}>
                                                    {resultat_calcul_evo_pref.capteur_evolution - resultat_calcul_evo_pref.cellules_evolution_2e === 1 && (
                                                        <>+{resultat_calcul_evo_pref.capteur_evolution - resultat_calcul_evo_pref.cellules_evolution_2e} mesure
                                                            secondaire gratuite non utilisée</>
                                                    )}
                                                    {resultat_calcul_evo_pref.capteur_evolution - resultat_calcul_evo_pref.cellules_evolution_2e > 1 && (
                                                        <>+{resultat_calcul_evo_pref.capteur_evolution - resultat_calcul_evo_pref.cellules_evolution_2e} mesures
                                                            secondaires gratuites non utilisées</>
                                                    )}
                                                    <Tooltip multiline
                                                             events={{hover: true, focus: true, touch: true}}
                                                             label={
                                                                 <p>L'abonnement de chaque 2e mesure d'un enregistreur
                                                                     est offerte</p>

                                                             }>
                                                        <span><IconeCircleQuestion/></span>
                                                    </Tooltip>
                                                </p>
                                            )}

                                            <div className={"en-ligne spacebetween"}
                                                 style={{gap: "0.5rem", marginTop: "auto"}}>

                                                <strong>Coût à l'achat
                                                    : {resultat_calcul_evo_pref.prix_achat}€ HT</strong>
                                                <strong className={"en-ligne"}>
                                                    Coût mensuel : {resultat_calcul_evo_pref.prix_mensuel}€ HT/mois
                                                    <Tooltip multiline
                                                             events={{hover: true, focus: true, touch: true}}

                                                             label={
                                                                 <p>
                                                                     {resultat_calcul_evo_pref.cellules_evolution} cellules
                                                                     virtuelles Evolution
                                                                     {resultat_calcul_evo_pref.cellules_evolution_2e > 0 && (
                                                                         <>
                                                                             <br/>
                                                                             {resultat_calcul_evo_pref.cellules_evolution_2e} cellules
                                                                             virtuelles Evolution (2ème de
                                                                             l'enregistreur,
                                                                             remise
                                                                             de {regles_paiement.deuxiemeCellule}%)
                                                                         </>
                                                                     )}
                                                                     {resultat_calcul_evo_pref.cellules_evolution_3e > 0 && (
                                                                         <>
                                                                             <br/>
                                                                             {resultat_calcul_evo_pref.cellules_evolution_3e} cellules
                                                                             virtuelles Evolution (3ème et + de
                                                                             l'enregistreur,
                                                                             remise de {regles_paiement.autre_cellules}%)
                                                                         </>
                                                                     )}
                                                                 </p>
                                                             }>
                                                        <span><IconeCircleQuestion/></span>
                                                    </Tooltip>
                                                </strong>
                                            </div>

                                            <button onClick={() => {
                                                setResultat_calcul_user({...resultat_calcul_evo_pref})
                                                nextStep();
                                            }}>Cette proposition me convient!
                                            </button>
                                        </div>
                                    )
                                }
                            </div>


                            {(
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Retour
                                    </button>
                                    <div>&nbsp;</div>
                                    {/*<button className={""} style={{padding: "1rem"}}*/}
                                    {/*        onClick={nextStep}>Continuer <IconeRight/>*/}
                                    {/*</button>*/}
                                </div>
                            )}
                        </div>
                    </Stepper.Step>


                    <Stepper.Step label={""/*"Enregistrement de l'email"*/}>
                        <div className={"stepper_inscription-page esti-cout"}>
                            <div className={"en-ligne spacebetween esti-prix-grid-mobile"}
                                 style={{alignItems: "stretch", width: "100%"}}>
                                <div className={"esti_prix_carte"}>

                                    <h3>Estimation personnalisée : </h3>

                                    <p>Financement : {achat ? "Achat + abonnement" : "Location + abonnement"}</p>
                                    {resultat_calcul_user.cellules_classic > 0 && (

                                        <p>Classic
                                            : {abonnements.find(a => a.id + "" == abonnement_classic_choisi)?.nom}</p>
                                    )}
                                    {resultat_calcul_user.cellules_evolution > 0 && (
                                        <p>Evolution
                                            : {abonnements.find(a => a.id + "" == abonnement_evolution_choisi)?.nom}</p>
                                    )}

                                    {resultat_calcul_user.capteur_classic > 0 && achat && (
                                        <p>{" - "}Achat de {resultat_calcul_user.capteur_classic} Classic
                                            ({prix_classic}€ HT
                                            unitaire) </p>
                                    )}
                                    {resultat_calcul_user.hub_classic > 0 && (
                                        <p>{" - "}Achat
                                            de {resultat_calcul_user.hub_classic} Hub{resultat_calcul_user.hub_classic > 1 && "s"} Classic
                                            ({prix_hub_classic}€ HT
                                            unitaire)</p>
                                    )}

                                    {resultat_calcul_user.capteur_evolution > 0 && achat && (
                                        <p>{" - "}Achat de {resultat_calcul_user.capteur_evolution} Evolution
                                            ({prix_evolution}€ HT
                                            unitaire)</p>
                                    )}
                                    {resultat_calcul_user.hub_evolution > 0 && (
                                        <p>{" - "}Achat
                                            de {resultat_calcul_user.hub_evolution} Hub{resultat_calcul_user.hub_evolution > 1 && "s"} Evolution
                                            ({prix_hub_evolution}€ HT
                                            unitaire) </p>
                                    )}

                                    {resultat_calcul_user.texte_remise_globale && (
                                        <p>{resultat_calcul_user.texte_remise_globale}</p>
                                    )}

                                    {resultat_calcul_user.capteur_evolution > resultat_calcul_user.cellules_evolution_2e && (
                                        <p className={"en-ligne"} style={{marginTop: "auto"}}>
                                            {resultat_calcul_user.capteur_evolution - resultat_calcul_user.cellules_evolution_2e === 1 && (
                                                <>+{resultat_calcul_user.capteur_evolution - resultat_calcul_user.cellules_evolution_2e} mesure
                                                    secondaire gratuite non utilisée</>
                                            )}
                                            {resultat_calcul_user.capteur_evolution - resultat_calcul_user.cellules_evolution_2e > 1 && (
                                                <>+{resultat_calcul_user.capteur_evolution - resultat_calcul_user.cellules_evolution_2e} mesures
                                                    secondaires gratuites non utilisées</>
                                            )}
                                            <Tooltip multiline
                                                     events={{hover: true, focus: true, touch: true}}
                                                     label={
                                                         <p>L'abonnement de chaque 2e mesure d'un enregistreur est
                                                             offerte</p>
                                                     }>
                                                <span><IconeCircleQuestion/></span>
                                            </Tooltip>
                                        </p>
                                    )}

                                    <strong style={{marginTop: "auto"}}>Coût à l'achat
                                        : {resultat_calcul_user.prix_achat}€ HT</strong>
                                    <strong className={"en-ligne"}>
                                        Coût mensuel : {resultat_calcul_user.prix_mensuel}€ HT/mois
                                        <Tooltip multiline
                                                 events={{hover: true, focus: true, touch: true}}
                                                 label={
                                                     <p>{resultat_calcul_user.cellules_classic} cellules virtuelles
                                                         Classic
                                                         <br/>
                                                         {resultat_calcul_user.cellules_evolution} cellules virtuelles
                                                         Evolution
                                                         {resultat_calcul_user.cellules_evolution_2e > 0 && (
                                                             <>
                                                                 <br/>
                                                                 {resultat_calcul_user.cellules_evolution_2e} cellules
                                                                 virtuelles
                                                                 Evolution (2ème de l'enregistreur, remise
                                                                 de {regles_paiement.deuxiemeCellule}%)
                                                             </>
                                                         )}
                                                         {resultat_calcul_user.cellules_evolution_3e > 0 && (
                                                             <>
                                                                 <br/>
                                                                 {resultat_calcul_user.cellules_evolution_3e} cellules
                                                                 virtuelles
                                                                 Evolution (3ème et + de l'enregistreur, remise
                                                                 de {regles_paiement.autre_cellules}%)
                                                             </>
                                                         )}
                                                     </p>
                                                 }>
                                            <span><IconeCircleQuestion/></span>
                                        </Tooltip>
                                    </strong>

                                </div>
                                <div className={"esti_prix_carte"}>
                                    <h3>Personnalisation de l'estimation : </h3>
                                    <div className={"en-ligne spacebetween"}>
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Enregistreurs Classic"}
                                            value={resultat_calcul_user.capteur_classic}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.capteur_classic = Number(e)
                                                edit.cellules_classic = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                        />
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Hubs Classic"}
                                            value={resultat_calcul_user.hub_classic}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.hub_classic = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                        />
                                    </div>
                                    <div className={"en-ligne spacebetween"}>
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Enregistreurs Evolution"}
                                            value={resultat_calcul_user.capteur_evolution}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.capteur_evolution = Number(e)
                                                edit.cellules_evolution = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                        />
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Hubs Evolution"}
                                            value={resultat_calcul_user.hub_evolution}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.hub_evolution = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                        />
                                    </div>
                                    <div className={"en-ligne spacebetween"}>
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Evolution, mesures secondaires"}
                                            value={resultat_calcul_user.cellules_evolution_2e}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.cellules_evolution_2e = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                            max={resultat_calcul_user.capteur_evolution}
                                        />
                                        <NumberInput
                                            className={"input-half-width"}
                                            label={"Evolution, mesures tertiaires et +"}
                                            value={resultat_calcul_user.cellules_evolution_3e}
                                            onChange={(e) => {
                                                let edit: resultat_calcul = {...resultat_calcul_user}
                                                edit.cellules_evolution_3e = Number(e)
                                                setResultat_calcul_user(edit)
                                            }}
                                        />
                                    </div>


                                </div>
                            </div>

                            <div className={"en-ligne esti-prix-grid-mobile"}>
                                <h3>👉 Recevez un récapitulatif par email de votre estimation : </h3>
                                <TextInput
                                    placeholder={"Votre adresse email"}
                                    value={email}
                                    onChange={e => set_email(e.target.value)}
                                    // leftSection={<IconeAt />}
                                />
                            </div>


                            {(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email)) && (
                                <div>
                                    {/*<button className={"big"} onClick={prevStep}>Retour</button>*/}
                                    <Checkbox
                                        label={<>Je souhaite être recontacté par Freshtime.</>}
                                        checked={veux_etre_contacter}
                                        onChange={e => setVeux_etre_contacter(e.target.checked)}
                                    />
                                    <br/>
                                    {!veux_etre_contacter ? (
                                        <Loading_button className={"big"}
                                                        style={{padding: "1rem"}}
                                                        onClick={handleValidation}
                                                        is_loading={btn_loading}
                                        >Recevoir mon récapitulatif par email <IconeCheck/></Loading_button>
                                    ) : (
                                        <>
                                            <TextInput
                                                label={"Prénom Nom"}
                                                required
                                                value={nom}
                                                onChange={e => setNom(e.target.value)}
                                            />
                                            <TextInput
                                                label={"Organisation (nom de votre société)"}
                                                required
                                                value={nom_entreprise}
                                                onChange={e => setNom_entreprise(e.target.value)}
                                            />
                                            <TextInput
                                                label={"N° de téléphone"}
                                                required
                                                value={tel}
                                                onChange={e => setTel(e.target.value)}
                                            />
                                            <br/>
                                            <Loading_button className={"big"}
                                                            style={{padding: "1rem"}}
                                                            onClick={handleValidation}
                                                            is_loading={btn_loading}
                                                            disabled={!nom || !nom_entreprise || !tel}
                                            >Recevoir mon récapitulatif par email et être
                                                recontacté <IconeCheck/></Loading_button>
                                        </>
                                    )}

                                </div>
                            )}

                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Retour
                                    </button>
                                    <div>&nbsp;</div>
                                </div>
                            )}
                        </div>
                    </Stepper.Step>


                    <Stepper.Completed>
                        <div className={"stepper_inscription-page esti-cout"}>
                            <h3>Merci de votre confiance.</h3>
                            <p>L'email vous a été envoyé. Pensez à vérifier votre boite spam si vous ne le recevez
                                pas.</p>
                            <p></p>
                            {true && (
                                <div className={"en-ligne spacebetween"} style={{width: '100%'}}>
                                    <div>&nbsp;</div>
                                    <button className={"sec"} style={{padding: "1rem"}}
                                            onClick={prevStep}><IconeLeft/> Revenir à l'estimation
                                    </button>
                                    <div>&nbsp;</div>
                                </div>
                            )}
                        </div>
                    </Stepper.Completed>
                </Stepper>


            </div>
        </div>

    );
}
