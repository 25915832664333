import {Cellule} from "../../../../types/cellule";
import React, {useEffect, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../../services/AxiosInterceptor";
import {Menu, Modal, Paper, Text, TextInput} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeTag} from "../../../../components/icone/solid_react/tag";
import {IconeChartLine} from "../../../../components/icone/solid_react/chart-line";
import "./modale_graphique_export.scss"
import "../../En_direct/En_direct.scss"
import {DateTimePicker} from "@mantine/dates";
import {Mesure} from "../../../../types/mesure";
import {
    afficherDate,
    afficherDateTime,
    afficherHeure,
    DateTZ
} from "../../../../services/GestionDates";
import {Carte_mesure} from "../../../../components/carte_mesure/carte_mesure";
import {BarChart, LineChart} from "@mantine/charts";
import {debug} from "util";
import {IconeFileExcel} from "../../../../components/icone/solid_react/file-excel";
import {Graphiques} from "../../../../services/Graphiques";
import {IconeLoader} from "../../../../components/icone/icone";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {Organisation} from "../../../../types/organisation";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeFilePdf} from "../../../../components/icone/solid_react/file-pdf";
import {IconeFileCsv} from "../../../../components/icone/solid_react/file-csv";

export function Modale_graphique_export({
                                            cellule,
                                            setCellule
                                        }: { cellule: Cellule, setCellule: React.Dispatch<Cellule | null> }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);
    const organisation = useSelector<RootState, Organisation | undefined>(state => state.organisation.list.find(o => o.id === cellule.organisation.id))
    let hier = new DateTZ();
    hier.setHours(hier.getHours() - 24);
    hier.setHours(0);
    hier.setMinutes(0);
    let maintenant = new DateTZ();
    maintenant.setHours(maintenant.getHours() + 1);
    maintenant.setMinutes(0);
    const [date_debut, setDate_debut] = useState<DateTZ>(hier);
    const [date_fin, setDate_fin] = useState<DateTZ>(maintenant);

    const [mesures, setMesures] = useState(<></>);

    const [mesures_data, setMesures_data] = useState<Mesure[]>([]);

    const [cellules_affiches, setCellules_affiches] = useState<{ id: string, nom: string }[]>([]);

    useEffect(() => {
        if (opened)
        {
            load_mesures(cellule.id, cellule.nom)
        }
    }, [date_debut, date_fin, opened]);

    function load_mesures(cellule_id: number | string, cellule_nom: string, ajouter?: boolean) {
        if (ajouter) {
            if (cellules_affiches.find(c => c.id == cellule_id + "")) {
                setCellules_affiches(cellules_affiches.filter(c => c.id != cellule_id))
                setMesures_data(mesures_data.filter(c => c.cellule_id != cellule_id))
                return;
            } else {
                setCellules_affiches([...cellules_affiches, {
                    id: cellule_id + "", nom: cellule_nom
                }])
            }
        } else {
            setCellules_affiches([{
                id: cellule_id + "", nom: cellule_nom
            }])
        }

        set_btn_loading(true);
        ajaxMesures(cellule_id)
            .then(data => {
                set_btn_loading(false);
                if (ajouter) {


                    setMesures_data(mesures_data.concat(data.mesures).sort((a, b) => {
                        if (a.timestamp === b.timestamp) return 0;
                        if (a.timestamp < b.timestamp) return -1;
                        else return 1
                    }))
                } else setMesures_data(data.mesures)

            })

    }

    useEffect(() => {

        if ("graphique" === "graphique") {
            let graph = new Graphiques(cellule, mesures_data, cellules_affiches)

            if (!graph.mesures || (graph.mesures && graph.mesures.length === 0)) {
                setMesures(<p className={"inline-tag"}>Aucune mesure disponible pour analyse.</p>)
            } else {
                setMesures(
                    <>
                        {graph.cartesUneLigne(true)}
                        {graph.lineGraph()}

                        {cellule.sonde_type?.type_de_donnee !== "bool" && graph.barGraph()}
                        <h1><br/>Excursions : </h1>
                        {graph.excursionsTable()}
                    </>
                )
            }

        }
    }, [mesures_data]);

    async function ajaxMesures(cellule_id: number | string) {
        let _mesures: any
        await ajax.get('/cellules/' + cellule_id + '/mesures' + "?debut=" + date_debut.toISOString() + "&fin=" + date_fin.toISOString() + "&light=true")
            .then(response => {
                _mesures = response.data

            }).catch(err => {
                set_btn_loading(false);
            })
        return _mesures;
    }


    function downloadCSV() {
        set_btn_loading(true);
        // ajaxMesures(cellule.id).then(data => {
        let csvString = ""
            + "Export des mesures de la cellule " + cellule.nom + "\n"
            + "Fuseau horaire : " + new DateTZ().getApplyiedTimezoneOffset() + "min\n"
            + "\nCellule;Date;Heure;" + cellule.sonde_type?.affichage_nom_mesure + " (" + cellule.sonde_type?.affichage_suffix + ")\n";

        let mesures: Mesure[] = JSON.parse(JSON.stringify(mesures_data));
        mesures.map(m => {
            let heure = new DateTZ(m.enregistrement)
            csvString += '"' + (cellules_affiches.find(ca => ca.id === m.cellule_id + "")?.nom ?? m.cellule_id) + '";"' + afficherDate(heure) + '";"'
                + heure.getHours().toString().padStart(2, '0') + ":" + heure.getMinutes().toString().padStart(2, '0') + ":" + heure.getSeconds().toString().padStart(2, '0')
                + '";' + m.mesure.replace(".", ",") + '\n'
        })

        const bom = '\uFEFF'; // UTF-8 BOM
        const blob = new Blob([bom + csvString], {type: 'text/csv;charset=utf-8;'});
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = "export_" + cellule.nom + "_" + afficherDate(date_debut).replace("/", "-") + "_" + afficherDate(date_fin).replace("/", "-");

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        set_btn_loading(false);
        // })

    }

    function downloadPDF() {
        set_btn_loading(true);

        ajax.get("/organisations/" + cellule.organisation.id
            + "/rapport/" + cellules_affiches.map(ca => ca.id) + "/"
            + new DateTZ(date_debut).toISOString() + "/" + new DateTZ(date_fin).toISOString()
            + "/" + (0 - new DateTZ().getTimezoneOffset()), {
            responseType: 'blob', // Important to handle the file as binary data
        })
            .then(response => {
                const blob = new Blob([response.data], {type: response.data.type});
                const downloadUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = 'rapport_' + cellule.nom
                    + '_' + afficherDate(date_debut).replace(/,/g, "-")
                    + '_' + afficherDate(date_fin).replace(/,/g, "-") + '.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
                URL.revokeObjectURL(downloadUrl); // Release memory
                set_btn_loading(false);
            }).catch(err => {
            set_btn_loading(false);
        })

        // })

    }

    function is_permission_granted() {
        return cellule.statut_promotionnel?.statut.can_use_graphiques ?? false
    }

    return (
        <>
            <button className={"sec"} onClick={handlers.open}><IconeChartLine/>Graphiques & exports</button>

            <button className={"sec"} onClick={handlers.open}><IconeFileExcel/>Exporter CSV</button>
            <button className={"sec"} onClick={handlers.open}><IconeFilePdf/>Exporter rapport PDF</button>
            <Modal opened={opened} onClose={handlers.close}
                   fullScreen={is_permission_granted()}
                   title={"Graphiques et exports de données pour la cellule " + cellule.nom}
                   className={"modale_graphique"}
                   style={{flex: 1}}>

                {!is_permission_granted() ? (
                    <>
                        <p className={"notice red"}>L'abonnement actif sur votre cellule ne vous permet pas de consulter vos
                            mesures sous forme de graphique ou d'en faire un export PDF ou CSV.</p>
                        <br />
                    </>
                ) : (
                    <>
                        <div className={"en-ligne justify_center"} style={{gap: "1rem"}}>
                            <div className={"en-ligne flex_align_center"}>
                                <p>Début : </p>
                                <DateTimePicker
                                    value={date_debut}
                                    locale={"Fr"}
                                    onChange={e => e ? setDate_debut(new DateTZ(e.getTime())) : ""}
                                />
                            </div>
                            <div className={"en-ligne flex_align_center"}>
                                <p>Fin : </p>
                                <DateTimePicker
                                    value={date_fin}
                                    locale={"Fr"}
                                    onChange={e => e ? setDate_fin(new DateTZ(e.getTime())) : ""}
                                />
                            </div>
                            <div className={"en-ligne flex_align_center"}>
                                {/*<Loading_button is_loading={btn_loading} onClick={() => load_mesures(cellule.id, cellule.nom)}><IconeChartLine/> Afficher les graphiques</Loading_button>*/}

                                {cellule.statut_promotionnel?.statut.can_superposer_cellules && (
                                    <>
                                        <Menu shadow="md" width={200}>
                                            <Menu.Target>
                                                {btn_loading ? (
                                                    <Loading_button is_loading={true} onClick={() => {
                                                    }}><IconeChartLine/> Ajouter une autre cellule sur le
                                                        graphique</Loading_button>
                                                ) : (
                                                    <button>
                                                        <IconeChartLine/> Ajouter une autre cellule sur le graphique
                                                    </button>
                                                )}
                                            </Menu.Target>
                                            <Menu.Dropdown className={"maxheight50"}>
                                                {organisation?.zones?.filter(z => z.nom !== "base.config.appareils").map(zone => (
                                                    <>
                                                        <Menu.Label>{zone.nom}</Menu.Label>
                                                        {zone.cellules_minimum?.map(cell => (
                                                            <Menu.Item onClick={() => load_mesures(cell.id, cell.nom, true)}
                                                                       disabled={cell.id + "" === cellule.id + ""}>
                                                                <div className={"en-ligne"}>
                                                                    {cellules_affiches.find(ca => ca.id === cell.id + "") &&
                                                                        <IconeCheck/>}
                                                                    {cell.nom}
                                                                </div>
                                                            </Menu.Item>
                                                        ))}

                                                    </>
                                                ))}
                                            </Menu.Dropdown>
                                        </Menu>
                                    </>
                                )}
                                <Loading_button is_loading={btn_loading}
                                                onClick={downloadCSV}><IconeFileExcel/> Exporter en CSV</Loading_button>
                                <Loading_button is_loading={btn_loading} onClick={downloadPDF}><IconeFilePdf/> Obtenir
                                    un
                                    rapport PDF</Loading_button>
                            </div>

                        </div>

                        <div className={"mesures mesures_6 mesures_hide_conditions mesures_mode_compact"}>
                            <div className={"mesures_historique vue-resume"}>
                                {mesures}
                            </div>
                        </div>
                    </>
                )}


            </Modal>
        </>

    )
}