import './Inscription_token.scss';
import React, {useEffect, useState} from "react";
import {Checkbox, NativeSelect, PasswordInput, TextInput} from "@mantine/core";
import {IconeLoader} from "../../../../components/icone/icone";
import {Link, useParams} from "react-router-dom";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import ajax from "../../../../services/AxiosInterceptor";
import {IconeAt} from "../../../../components/icone/solid_react/at";
import {IconePhone} from "../../../../components/icone/solid_react/phone";
import {IconeMobile} from "../../../../components/icone/solid_react/mobile";
import {IconeMobileScreen} from "../../../../components/icone/solid_react/mobile-screen";
import env_config from "../../../../env_config";

export function Inscription_token() {

    let token = useParams().token;

    const [nom_affichage, set_nom_affichage] = useState("");
    const [nom_famille, set_nom_famille] = useState("");
    const [email, set_email] = useState("");
    const [tel_indicatif, set_tel_indicatif] = useState("+33");
    const [telephone, set_telephone] = useState("");
    const [mot_de_passe, set_mot_de_passe] = useState("");
    const [mot_de_passe_confirme, set_mot_de_passe_confirme] = useState("");
    const [conditions_generales_utilisations, set_conditions_generales_utilisations] = useState(false);

    const [organisations, set_organisations] = useState<string[]>([]);

    const [token_valide, set_token_valide] = useState<boolean | null>(null);
    const [btn_loading, set_btn_loading] = useState(false);

    useEffect(() => {
        ajax.get("/inscription/token/" + token).then(response => {
            set_token_valide(true)
            set_nom_affichage("")
            set_nom_famille("")
            set_email(response.data.utilisateur.email)
            set_telephone(response.data.utilisateur.tel)

            set_organisations(response.data.organisations)
        }).catch(error => {
            set_token_valide(false)
        })
    }, [])

    function handleValidation(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let tel_edit = telephone;

        if (!/^\+33\s?[1-9](?:\d{8}|(?:\d{2}\s?){4})$|^\+377\s?[4-9](?:\d{7}|(?:\d{2}\s?){3})$/.test(tel_indicatif + telephone))
        {
            if (telephone.startsWith("0")){
                if (window.confirm("Le numéro de téléphone n'est pas valide, l'indicatif "+tel_indicatif+" remplace le 0 au " +
                    "début de votre numéro de téléphone, et donc vous ne devez pas le mettre. \nEst-ce que cette " +
                    "correction vous convient : "+tel_indicatif + " " + telephone.substring(1) +" ?"))
                {
                    tel_edit = telephone.substring(1);
                }
                else{
                    return
                }
            } else{
                alert("Le numéro de téléphone n'est pas valide.\n")
                return;
            }
        }
        if (mot_de_passe !== mot_de_passe_confirme) {
            alert("Les mots de passes ne correspondent pas.")
            return;
        } else if (!conditions_generales_utilisations) {
            alert("Vous devez accepter les conditions pour pouvoir poursuivre.")
            return;
        }
        if (!(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(email)))
        {
            alert("L'email renseigné n'est pas valide.")
            return;
        }
        if (!(/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe)))
        {
            alert("Le mot de passe ne satisfait pas les conditions requises de sécurité.")
            return;
        }


        set_btn_loading(true)

        ajax.post("/inscription/token/" + token, {
            "email" : email,
            "nom_affichage" : nom_affichage,
            "nom_famille" : nom_famille,
            "tel" : tel_indicatif + tel_edit,
            "password" : mot_de_passe,
            "accepte_cgu": conditions_generales_utilisations
        }).then(response => {
            window.location.href = "/login?msg=inscription";

            set_btn_loading(false)
        }).catch(error => {
            set_token_valide(false)
            set_btn_loading(false)
        })

    }

    return (
        <div className={"app inscription-token"}>
            <div className={"inscription_form"}>
                <img src={"/logo_freshtime.svg"} alt=""/>

                {!token_valide ? (
                        token_valide === null ? (
                                <div>
                                    <br/><br/>
                                    <p className={"en-ligne"}><IconeLoader /> Chargement de vos données, merci de
                                        patienter...</p>
                                    <br/><br/>
                                </div>
                        ) : (
                            <p className={"notice"}>Désolé, il semblerai que ce lien ai expiré. Merci de contacter votre organisation ou les équipes Freshtime.</p>
                        )
                ) : (
                    <form action="Auth/Inscription/Token#" onSubmit={handleValidation}>
                        <div className={"fullwidth"}>
                            <h3>Bienvenue sur la plateforme Freshtime!</h3>
                            <br/>
                            <p>Vous avez été invité à rejoindre {organisations.length > 1 ? "les organisations" : "l'organisation"} : </p>
                            {organisations.map(orga => (
                                <>
                                    <i className={"inline-tag"}>{orga}</i>{' '}
                                </>
                            ))}

                            <br/>
                            <p style={{fontSize: "var(--texte-size-petit)"}}>Merci de completer le formulaire ci dessous
                                pour valider la création de votre compte et pour pouvoir acceder aux données : </p>
                        </div>

                        <label>
                            Adresse email *
                            <TextInput
                                type="email"
                                name="email"
                                placeholder="email@email.fr"
                                // leftSection={<IconeAt />}
                                required
                                value={email}
                                onChange={e => set_email(e.target.value)}
                            />
                        </label>
                        <label>
                            Numéro de téléphone
                            <div className={"en-ligne no-wrap"}>
                                <NativeSelect
                                    value={tel_indicatif}
                                    leftSection={<IconeMobileScreen />}
                                    onChange={(event) => set_tel_indicatif(event.currentTarget.value)}
                                    data={[
                                        {label: "+33 (France)", value: "+33"},
                                        {label: "+377 (Monaco)", value: "+377"}]}
                                />
                                <TextInput
                                    type="tel"
                                    name="tel"
                                    placeholder=""
                                    // leftSection={<>{tel_indicatif} </>}
                                    value={telephone}
                                    onChange={e => set_telephone(e.target.value)}
                                />
                            </div>
                        </label>
                        <label>
                            Prénom *
                            <TextInput
                                placeholder="Elodie"
                                required
                                value={nom_affichage}
                                onChange={e => set_nom_affichage(e.target.value)}
                            />
                        </label>
                        <label>
                            Nom de famille *
                            <TextInput
                                placeholder=""
                                required
                                value={nom_famille}
                                onChange={e => set_nom_famille(e.target.value)}
                            />
                        </label>
                        <label>
                            Mot de passe *
                            <PasswordInput
                                placeholder="Mot de passe"
                                required
                                value={mot_de_passe}
                                onChange={e => set_mot_de_passe(e.target.value)}
                                error={mot_de_passe === "" || /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe) ? null : true }
                            />
                        </label>
                        <label>
                            Confirmer le mot de passe *
                            <PasswordInput
                                placeholder="Mot de passe"
                                required
                                value={mot_de_passe_confirme}
                                error={mot_de_passe_confirme !== "" && mot_de_passe_confirme !== mot_de_passe ? true : null }
                                onChange={e => set_mot_de_passe_confirme(e.target.value)}
                            />
                        </label>

                        {!(mot_de_passe === "" || /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(mot_de_passe)) && (
                            <p className={"notice red fullwidth"}>Le mot de passe doit contenir une lettre ET un chiffre, et faire au moins 8 caractères de long.</p>
                        )}
                        {mot_de_passe_confirme !== "" && mot_de_passe_confirme !== mot_de_passe && (
                            <p className={"notice red fullwidth"}>Les mot de passes ne correspondent pas</p>
                        )}


                        <Checkbox
                            label={<>J'ai lu et j'accepte les <a href={env_config.url_cg_utilisation} target="_blank">conditions
                                générales d'utilisation</a> ainsi que les <a href={env_config.url_cg_vente}
                                                                             target="_blank">conditions générales
                                de ventes</a></>}
                            required
                            checked={conditions_generales_utilisations}
                            onChange={e => set_conditions_generales_utilisations(e.target.checked)}
                            style={{width: "90%"}}
                        />


                        <Loading_button is_loading={btn_loading} onClick={() => {
                        }} className={"big"}>Valider votre inscription</Loading_button>

                    </form>
                )}


            </div>
        </div>

    );
}
