import {Navbar} from "../../../components/navbar/navbar";
import {Bandeau_user} from "../../../components/sidebar/bandeau_user/bandeau_user";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Organisation} from "../../../types/organisation";
import {
    Accueil_organisation,
} from "./accueil_organisation/accueil_organisation";
import './Accueil.scss'
import ajax from "../../../services/AxiosInterceptor";
import {IconeLoader} from "../../../components/icone/icone";
import {Notifications} from "../../../types/notifications";
import {afficherDateTime, DateTZ} from "../../../services/GestionDates";
import {useNavigate} from "react-router-dom";
import env_config from "../../../env_config";
import {
    loadInterfaceFromLocalStorage,
    ouvrirCollapsesSansEcrireDansLocalStorage,
    setAcceuilCollapseState,
    setInterfaceState, setMobile_sidebar_icon
} from "../../../store/interfaceSlice";
import Sidebar from "../../../components/sidebar/Sidebar";
import {IconeMagnifyingGlass} from "../../../components/icone/solid_react/magnifying-glass";
import {IconeBell} from "../../../components/icone/solid_react/bell";
import {IconeBullhorn} from "../../../components/icone/solid_react/bullhorn";
import Joyride, {ACTIONS, CallBackProps, EVENTS, STATUS} from "react-joyride";
import {Accueil_intro_tour} from "../../../components/Tours/Apres_inscription/Accueil_intro_tour";
import {setPasApasEtat} from "../../../store/pasApasSlice";

export function Accueil() {
    const organisations = useSelector<RootState, Organisation[]>(state => state.organisation.list)

    const [recherche, setRecherche] = useState<string>("")

    const [notifications, setNotifications] = useState<Notifications[]|null>(null)


    const navigate = useNavigate();

    const dispatch = useDispatch();
    dispatch(setMobile_sidebar_icon("bell"))

    useEffect(() => {
        dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));

        query_notifications();
        const interval_clear = setInterval(() => {
            query_notifications();
        }, env_config.refresh_rate);

        return () => {
            clearInterval(interval_clear);
        }
    }, [])

    useEffect(() => {
        if (recherche_min() !== "")
        {
            dispatch(ouvrirCollapsesSansEcrireDansLocalStorage());
        }
        else {
            dispatch(setInterfaceState(loadInterfaceFromLocalStorage()));
        }
    }, [recherche])

    function recherche_min()
    {
        if (recherche.length > 2) return recherche
        else return ""
    }

    function query_notifications()
    {
        ajax.get("/utilisateur/dashboard_notifications").then(response => {
            console.log("En_direct - Refresh des notifications OK")

            dispatch(setPasApasEtat("accueil_notif_loaded"))
            setNotifications(response.data.notifications)
        }).catch(error => {})
    }


    let plus_vieille_orga_creation = new DateTZ();
    organisations.forEach((organisation) => {
        if (new DateTZ(organisation.creation) < plus_vieille_orga_creation) {
            plus_vieille_orga_creation = new DateTZ(organisation.creation);
        }
    })

    return (
        <div className={"app"}>
            <Navbar/>
            <div className={"content"}>
                <div className={"organisations"}>
                    <div className={"recherche"}>
                        <IconeMagnifyingGlass />
                        <input type="text"
                               placeholder={"Recherche... (Organisation, cellules, enregistreurs, ...)"}
                               className={"big_input"}
                               value={recherche}
                               onChange={event => setRecherche(event.target.value)}
                        />
                    </div>

                    {organisations.length > 0 ? "" : "Votre compte n'est lié à aucune organisation..."}

                    {organisations.map(organisation => {

                        return (
                            <>
                                {/*<LazyLoad height={300} offset={300} key={organisation.id}>*/}
                                    <Accueil_organisation organisation={organisation} key={organisation.id} recherche={recherche_min()}/>
                                {/*</LazyLoad>*/}
                            </>

                        )
                    })}

                </div>
            </div>

            <Sidebar>
                <Bandeau_user/>

                {notifications ? (
                    <div className={"notifications"}>
                        <h3><IconeBell/> Dernières notifications </h3>
                        {notifications.length === 0 && (
                            <p className={"inline-tag"}>Vous n'avez aucune notification.</p>
                        )}
                        {notifications.filter(n => new DateTZ(n.envoi_heure) >= plus_vieille_orga_creation).map(notification => {
                            return (
                                <div className={
                                    (notification.type === "Global" ? "carte_notification jaune" : "carte_notification red")
                                    + (notification.cellule && " clickable")
                                }
                                     key={notification.id} onClick={() => {
                                         if (notification.cellule)
                                         {
                                             navigate("/cellule/" + notification.cellule.id + "?dt=" + notification.envoi_heure)
                                         }
                                }}>
                                    <div className={"titre"}>
                                        <div style={{fontWeight: "bold"}}>
                                            {notification.type === "Anomalie-ntf"
                                                ? <div className={"notif-en-tete"}><IconeBell />{notification.organisation?.nom}</div>
                                                : <div className={"notif-en-tete"}><IconeBullhorn />{"Information"}</div>}
                                        </div>
                                        <i>{afficherDateTime(new DateTZ(notification.envoi_heure))}</i>
                                    </div>
                                    <p className={"infos"}>{notification.cellule && "↪"} {notification.message}</p>
                                </div>
                            )
                        })}

                    </div>
                ) : (
                    <>
                        &nbsp;<br />
                        <div className={"en-ligne"} style={{justifyContent: "center"}}><IconeLoader />Chargement...</div>
                    </>

                )}


            </Sidebar>


            <Accueil_intro_tour organisations={organisations} />



        </div>





    );
}
