import "./Tableau.scss"
import React, {useEffect, useState} from "react";
import {
    flexRender,
    Column, Table, VisibilityState
} from "@tanstack/react-table";
import {IconeLoader} from "../icone/icone";
import {NativeSelect, Popover} from "@mantine/core";
import {IconeArrowUpAZ} from "../icone/solid_react/arrow-up-a-z";
import {IconeArrowDownZA} from "../icone/solid_react/arrow-down-z-a";
import {IconeEye} from "../icone/solid_react/eye";
import {IconeAnglesLeft} from "../icone/solid_react/angles-left";
import {IconeAngleLeft} from "../icone/solid_react/angle-left";
import {IconeAngleRight} from "../icone/solid_react/angle-right";
import {IconeAnglesRight} from "../icone/solid_react/angles-right";

type col_local_storage = {
    table_id: string
    state_cols:Column<any, unknown>[]
}

export function Tableau({table, donnees}: { table: Table<any>, donnees: any[] | null }) {

    const [col_loaded, setCol_loaded] = useState(false);

    function getTableID()
    {
        return "tab-" + table.getAllLeafColumns().map(c => c.id + "-") + "-url" + window.location.href;
    }
    function getLocalStorageFull():col_local_storage[]
    {
        let localsto = localStorage.getItem('table_cols');
        let state_cols:col_local_storage[] = []
        if (localsto) {
            state_cols = JSON.parse(localsto);
        }
        return state_cols
    }
    function getLocalStorage():col_local_storage|null
    {
        let state_cols = getLocalStorageFull()
        let state_col:col_local_storage|null = null
        state_cols.map(col => {
            if (col.table_id === getTableID())
            {
                state_col = col;
            }
        })

        return state_col
    }

    const [written_local_storage, setWritten_local_storage] = useState<string>("");

    useEffect(() => {
        //Ecriture des colonnes dans le localstorage
        if (col_loaded)
        {
            let localsto = getLocalStorageFull()
            let cette_table = getLocalStorage();
            if (cette_table)
            {
                localsto = localsto.map(_table => {
                    if (cette_table && _table.table_id === cette_table.table_id)
                    {
                        _table.state_cols = table.getVisibleLeafColumns()
                    }
                    return _table
                })
            }
            else{
                localsto.push({
                    table_id: getTableID(),
                    state_cols: table.getVisibleLeafColumns()
                })
            }

            const serializedState = JSON.stringify(localsto);
            if (written_local_storage !== serializedState){
                localStorage.setItem('table_cols', serializedState);
                setWritten_local_storage(serializedState)
                console.log("Ecriture")
            }

        }

    }, [table.getVisibleLeafColumns()]) //


    useEffect(() => {
        //Lecture des colonnes dans le localstorage
        console.log("lecture")
        let _table = getLocalStorage()
        if (_table) {
            let retour:VisibilityState = {};
            table.getAllLeafColumns().map(col_toutes => {
                if (_table)
                {
                    retour[col_toutes.id] = _table.state_cols.filter(col_visible => col_visible.id === col_toutes.id ).length > 0
                }
            })
            table.setColumnVisibility(retour);
        }

        setTimeout(() => {setCol_loaded(true)}, 1000);
    }, [])

    if (donnees === null) {
        return (
            <div className={"tableauContainer"}>
                {}
                <table>
                    <div className={"en-ligne"} style={{justifyContent: "center"}}><IconeLoader />Chargement...
                    </div>
                </table>
            </div>
        )
    }


    return (
        <div className={"tableauContainer"}>
            <table>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div>
                                            <span onClick={header.column.getToggleSortingHandler()}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}

                                                {{
                                                    asc: <IconeArrowUpAZ/>,
                                                    desc: <IconeArrowDownZA/>,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </span>


                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} table={table}/>
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => {
                    return (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => {
                                return (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                {table.getRowModel().rows.length === 0 && (
                    <tr>
                        <td colSpan={100}>
                            <p className={"notice"}>Aucune ligne à afficher...</p>
                        </td>
                    </tr>
                )}

                </tbody>

            </table>

            <div className="en-ligne pagination">
                <Popover width={200} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                        <button className={"sec"}><IconeEye/> Colonnes</button>
                    </Popover.Target>
                    <Popover.Dropdown>
                        {table.getAllLeafColumns().map(column => {
                            return (
                                <div key={column.id} className="colonnes_selector">
                                    <label>
                                        <input
                                            {...{
                                                type: 'checkbox',
                                                checked: column.getIsVisible(),
                                                onChange: column.getToggleVisibilityHandler(),
                                            }}
                                        />{' '}

                                        {column.id}
                                    </label>
                                </div>
                            )
                        })}
                    </Popover.Dropdown>
                </Popover>
                <span className="flex items-center gap-1">
                  {/*<div>Page</div>*/}
                    <strong>Page {' '}
                        {table.getState().pagination.pageIndex + 1} sur{' '}
                        {table.getPageCount()}
                  </strong>
                </span>
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    <IconeAnglesLeft/>
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    <IconeAngleLeft/>
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    <IconeAngleRight/>
                </button>
                <button
                    className="border rounded p-1"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    <IconeAnglesRight/>
                </button>

            </div>


        </div>

    )
}

function Filter({
                    column,
                    table,
                }: {
    column: Column<any, any>
    table: Table<any>
}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()

    return typeof firstValue === 'number' ? (
        <div className="table_input_min_max">
            <input
                type="number"
                value={(columnFilterValue as [number, number])?.[0] ?? ''}
                onChange={e =>
                    column.setFilterValue((old: [number, number]) => [
                        e.target.value,
                        old?.[1],
                    ])
                }
                placeholder={`Min`}
                className="w-24 border shadow rounded"
            />
            <input
                type="number"
                value={(columnFilterValue as [number, number])?.[1] ?? ''}
                onChange={e =>
                    column.setFilterValue((old: [number, number]) => [
                        old?.[0],
                        e.target.value,
                    ])
                }
                placeholder={`Max`}
                className="w-24 border shadow rounded"
            />
        </div>
    ) : (
        <input
            type="text"
            value={(columnFilterValue ?? '') as string}
            onChange={e => column.setFilterValue(e.target.value)}
            placeholder={`Recherche...`}
            className="input_recherche_table"
        />
    )
}