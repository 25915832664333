import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../../../store/interfaceSlice";
import {Commercial, Commercial_primes} from "../../../../types/commercial";
import ajax from "../../../../services/AxiosInterceptor";
import {Capteur} from "../../../../types/capteur";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {
    Autocomplete,
    Checkbox,
    Input,
    Modal,
    NativeSelect,
    NumberInput,
    Switch,
    TextInput,
    Tooltip
} from "@mantine/core";
import {afficherDateTime, DateTZ} from "../../../../services/GestionDates";
import {IconeReceipt} from "../../../../components/icone/solid_react/receipt";
import {IconeTemperatureFull} from "../../../../components/icone/solid_react/temperature-full";
import {IconeShop} from "../../../../components/icone/solid_react/shop";
import {Commerciaux_admin_primes_input} from "../../Prestataire/Commerciaux_admin/Commerciaux_admin";
import {Organisation} from "../../../../types/organisation";
import {Extension} from "../../../../types/extension";
import {IconeProjector} from "../../../../components/icone/solid_react/projector";
import {getAffichageNom} from "../../../../services/UserService";
import {useDisclosure} from "@mantine/hooks";
import {DateInput, DateValue} from "@mantine/dates";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {permissionIsGranted} from "../../../../services/permissionsService";



export function Commercial_user_capteurs({organisations, commercial, capteurs}:{organisations:Organisation[], commercial:Commercial|null, capteurs:Capteur[]|null}) {

    // const [organisations, setOrganisations] = useState<Organisation[]>([]);
    // const [commercial, setCommercial] = useState<Commercial|null>(null);
    // const [capteurs, setCapteurs] = useState<Capteur[] | null>(() => null);

    // useEffect(() => {
    //     ajax.get("/commercial/" + userId + "/capteurs").then(response => {
    //         setCommercial(response.data.commercial);
    //         let orgas:Organisation[] = response.data.organisations;
    //         setOrganisations(orgas);
    //
    //         let capteurs:Capteur[] = response.data.capteurs;
    //         capteurs = capteurs.map(capteur => {
    //             capteur.organisation = orgas.find(o => o.id+"" == capteur.organisation_id+"") ?? null
    //             return capteur
    //         })
    //         setCapteurs(capteurs);
    //     }).catch(err => {})
    // }, []);


    const Capteurs_Tableau_admin: typeDonnee[] = [
        {key: "uuid", nom: "UUID", noedit: true},
        {key: "numero_serie", nom: "Numéro de série"},
        {key: "ref", nom: "Réf"},
        {key: "produit.nom", nom: "Produit"},
        {key: "organisation.nom", nom: "Organisation"},
        {key: "derniere_mesure", nom: "Dernière mesure", edit_display_value: (value) => afficherDateTime(value)},
        {key: "hub.uuid", nom: "Hub"},
        {key: "categorie_abonnement", nom: "Catégorie d'abonnement"},
        {key: "dateDeVente", nom: "Date de vente", edit_display_value: (value) => afficherDateTime(value)},
        {key: "prixDeVente", nom: "Prix de vente", type: "number", suffix: "€", edit_display_value: texte => texte === 0 ? null : texte},
        {key: "venteValide", nom: "Vente validé ?", type: "boolean"},
    ]

    const [modal_enregistrer_vente,handlers_modal_enregistrer_vente] = useDisclosure(false);
    const [enregistrer_vente_capteur_uuid, setEnregistrer_vente_capteur_uuid] = useState("");
    const [enregistrer_vente_date, setEnregistrer_vente_date] = useState<DateValue>(new DateTZ());
    const [modale_enregistrer_vente_garder_ouvert, setModale_enregistrer_vente_garder_ouvert] = useState(false);

    const [modal_enregistrer_vente_custom,handlers_modal_enregistrer_vente_custom] = useDisclosure(false);
    const [custom_nom, setCustom_nom] = useState("");
    const [custom_ref, setCustom_ref] = useState("");
    const [custom_date, setCustom_date] = useState<DateValue>(new DateTZ());
    const [custom_prix, setCustom_prix] = useState(0);
    const [custom_comm, setCustom_comm] = useState(commercial?.commissionVente);

    const [btn_loading, setBtn_loading] = useState(false);

    function handleEnregistrerVente() {
        if (!enregistrer_vente_capteur_uuid || !enregistrer_vente_date) {
            alert("Formulaire pas rempli. Merci de verifier.");
            return;
        }
        setBtn_loading(true);
        ajax.post("/commercial/" + commercial?.user?.id + "/enregistrer_vente", {
            capteur_uuid: enregistrer_vente_capteur_uuid,
            dateDeVente: enregistrer_vente_date.toISOString(),
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Vente enregistrée.",
                message: '',
                icon: <IconeCheck/>,
            });

            if (!modale_enregistrer_vente_garder_ouvert) {
                handlers_modal_enregistrer_vente.close();
            }
            setEnregistrer_vente_capteur_uuid("");

            setBtn_loading(false)
        }).catch(error => {
            setBtn_loading(false)
        })
    }

    function handleEnregistrerVenteCustom() {
        if (!custom_nom || !custom_ref || !custom_comm || !custom_prix || !custom_date) {
            alert("Formulaire pas rempli. Merci de verifier.");
            return;
        }
        setBtn_loading(true);
        ajax.post("/commercial/" + commercial?.user?.id + "/enregistrer_vente_custom", {
            "nom": custom_nom,
            "ref": custom_ref,
            "date": custom_date.toISOString(),
            "prix": custom_prix,
            "commission": custom_comm,
        }).then(response => {
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Vente enregistrée.",
                message: '',
                icon: <IconeCheck/>,
            });

            if (!modale_enregistrer_vente_garder_ouvert) {
                handlers_modal_enregistrer_vente_custom.close();
            }

            setBtn_loading(false)
        }).catch(error => {
            setBtn_loading(false)
        })
    }

    return (
        <div>
            {!capteurs ? (
                <Loading_div />
            ) : (
                <>
                    <div className="en-ligne">
                        <button onClick={handlers_modal_enregistrer_vente.toggle}>Enregistrer une vente</button>
                        {permissionIsGranted(undefined, "ADMIN") && (
                            <button onClick={handlers_modal_enregistrer_vente_custom.toggle}>Enregistrer une vente
                                CUSTOM</button>
                        )}
                    </div>

                    {capteurs?.length === 0 && (
                        <p className={"notice red"}>Aucun capteur.</p>
                    )}
                    <Tableau_helper
                        typeDonnee={Capteurs_Tableau_admin}
                        donnees={capteurs}
                        // boutons={<button onClick={() => {window.open("https://www.thinksrs.com/downloads/programs/therm%20calc/ntccalibrator/ntccalculator.html", '_blank');}}>Calculateur en ligne pour les coefficients NTC</button>}
                        // setTable={(_table) => {
                        //
                        //     setSelection(_table.getSelectedRowModel().rows.map((row) => row.original));
                        // }}
                        // enable_selection={true}
                    />
                </>
            )}

            <Modal opened={modal_enregistrer_vente} onClose={handlers_modal_enregistrer_vente.close} title="Enregistrer une vente">

                <Autocomplete
                    label="Référence du capteur vendu : "
                    data={capteurs?.map(capteur =>  {
                        return capteur.uuid
                    })}
                    value={enregistrer_vente_capteur_uuid ?? ""}
                    onChange={e => setEnregistrer_vente_capteur_uuid(e)}
                />

                <DateInput
                    label="Date de vente"
                    locale="fr"
                    valueFormat="DD/MM/YYYY"
                    value={enregistrer_vente_date}
                    onChange={e => setEnregistrer_vente_date(e)}
                />

                <p>&nbsp;</p>
                <Checkbox
                    label={"Garder la fenetre ouverte"}
                    checked={modale_enregistrer_vente_garder_ouvert}
                    onChange={e => setModale_enregistrer_vente_garder_ouvert(e.target.checked)}
                />
                <Loading_button is_loading={btn_loading} onClick={handleEnregistrerVente}>
                    Valider
                </Loading_button>

            </Modal>

            <Modal opened={modal_enregistrer_vente_custom} onClose={handlers_modal_enregistrer_vente_custom.close} title="Enregistrer une vente CUSTOM">

                <TextInput
                    label="Nom de l'objet vendu : "
                    value={custom_nom ?? ""}
                    onChange={e => setCustom_nom(e.target.value)}
                />

                <TextInput
                    label="Réf de l'objet vendu : "
                    value={custom_ref ?? ""}
                    onChange={e => setCustom_ref(e.target.value)}
                />

                <DateInput
                    label="Date de vente"
                    locale="fr"
                    valueFormat="DD/MM/YYYY"
                    value={custom_date}
                    onChange={e => setCustom_date(e)}
                />

                <NumberInput
                    label="Prix vendu : "
                    value={custom_prix ?? ""}
                    onChange={e => setCustom_prix(Number(e))}
                    suffix={"€"}
                    step={0.01}
                />

                <NumberInput
                    label="Commission à utiliser : "
                    value={custom_comm ?? ""}
                    onChange={e => setCustom_comm(Number(e))}
                    suffix={"%"}
                />

                <p className={'notice grey margintop'}>Attention: ces données ne pourront plus être modifiée une fois validé.</p>

                <p>&nbsp;</p>
                <Checkbox
                    label={"Garder la fenetre ouverte"}
                    checked={modale_enregistrer_vente_garder_ouvert}
                    onChange={e => setModale_enregistrer_vente_garder_ouvert(e.target.checked)}
                />
                <Loading_button is_loading={btn_loading} onClick={handleEnregistrerVenteCustom}>
                    Valider
                </Loading_button>

            </Modal>

        </div>

    );
}
