import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {setMobile_sidebar_icon} from "../../../../store/interfaceSlice";
import {Commercial, Commercial_primes, Resultat_calcul_commercial} from "../../../../types/commercial";
import ajax from "../../../../services/AxiosInterceptor";
import {Capteur} from "../../../../types/capteur";
import {Loading_div} from "../../../../components/loading_div/loading_div";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {afficherDate, afficherDateTime, DateTZ} from "../../../../services/GestionDates";
import {Organisation} from "../../../../types/organisation";
import {DateInput} from "@mantine/dates";
import {NativeSelect, Paper, Switch, Text} from "@mantine/core";
import {Loading_button} from "../../../../components/loading_button/loading_button";
import {IconeTimelineArrow} from "../../../../components/icone/solid_react/timeline-arrow";
import {getFilteredChartTooltipPayload, LineChart} from "@mantine/charts";

type affichage_tableau = {
    date: string,
    type: string,
    ref: string,
    infos: string,
    montant: number | null,
    comm_pourcent: number | null,
    comm_euro: number,
}


export function Commercial_user_dashboard({organisations, commercial, capteurs}: {
    organisations: Organisation[],
    commercial: Commercial | null,
    capteurs: Capteur[] | null
}) {

    const [resultat_calcul, setResultat_calcul] = useState<Resultat_calcul_commercial | null>(null);
    const [donnees_tableau_recap, setDonnees_tableau_recap] = useState<affichage_tableau[]>([]);


    const [utiliserPrime, setUtiliserPrime] = useState(false);
    const [anneeInput, setAnneeInput] = useState<string>(new DateTZ().getFullYear() + "");

    function queryCalculs() {
        if (!commercial) return;
        setResultat_calcul(null);
        ajax.post("/commercial/" + commercial?.user?.id + "/calcul_commission", {
            "dateInput": anneeInput + "-12-31T23:59:00Z",
            "utiliserPrime": utiliserPrime,
        }).then(response => {
            setResultat_calcul(response.data.calcul);
        }).catch(error => {
        })

    }

    useEffect(() => {
        queryCalculs();
    }, [commercial]);

    useEffect(() => {
        if (!resultat_calcul) return;
        let datas: affichage_tableau[] = [];
        resultat_calcul.capteurs_cette_annee.map(capteur => {
            datas.push({
                "date": capteur.dateDeVente,
                "type": "Vente",
                "ref": capteur.uuid,
                "infos": capteur.produit.nom,
                "montant": capteur.prixDeVente,
                "comm_pourcent": resultat_calcul.comm_pourcentage_vente,
                "comm_euro": Math.ceil(capteur.prixDeVente * (resultat_calcul.comm_pourcentage_vente / 100) * 100) / 100,
            })
        })
        resultat_calcul.paiements_abo_annee_precedente.map(paiement => {
            datas.push({
                "date": paiement.payeLe ?? "Jamais",
                "type": "Abonnement mensuel",
                "ref": (paiement.capteur ? "Enregistreur " + paiement.capteur : "Enregistreur inconnu") +" Abonnement : " + paiement.statut.nom,
                "infos": "Enregistreur vendu les années précédentes. Abonnement : " + paiement.statut.nom,
                "montant": paiement.payeMontant,
                "comm_pourcent": resultat_calcul.comm_pourcentage_annee_precedentes,
                "comm_euro": Math.ceil(paiement.payeMontant * (resultat_calcul.comm_pourcentage_annee_precedentes / 100) * 100) / 100,
            })
        })
        resultat_calcul.paiements_abo_cette_annee.map(paiement => {
            datas.push({
                "date": paiement.payeLe ?? "Jamais",
                "type": "Abonnement mensuel",
                "ref": (paiement.capteur ? "Enregistreur " + paiement.capteur : "Enregistreur inconnu") +" Abonnement : " + paiement.statut.nom,
                "infos": "Enregistreur vendu cette année.",
                "montant": paiement.payeMontant,
                "comm_pourcent": resultat_calcul.comm_pourcentage_abonnement,
                "comm_euro": Math.ceil(paiement.payeMontant * (resultat_calcul.comm_pourcentage_abonnement / 100) * 100) / 100,
            })
        })
        commercial?.paiementEffectues.map(paiement => {
            if (new DateTZ(paiement.date).getFullYear() + "" === anneeInput) {
                datas.push({
                    "date": paiement.date,
                    "type": "Paiement commission",
                    "ref": "",
                    "infos": "",
                    "montant": null,
                    "comm_pourcent": null,
                    "comm_euro": paiement.montant,
                })
            }
        })
        commercial?.ventesCustom.map(vente => {
            if (new DateTZ(vente.date).getFullYear() + "" === anneeInput) {
                datas.push({
                    "date": vente.date,
                    "type": "Vente custom",
                    "ref": vente.ref,
                    "infos": vente.nom,
                    "montant": vente.prix,
                    "comm_pourcent": vente.commission,
                    "comm_euro": vente.prix * (vente.commission / 100),
                })
            }
        })

        datas = datas.sort((a, b) => a.date < b.date ? 1 : -1);


        setDonnees_tableau_recap(datas);
    }, [resultat_calcul]);

    const capteurs_tableau: typeDonnee[] = [
        {key: "date", nom: "Date", edit_display_value: (value) => afficherDateTime(value)},
        {key: "type", nom: "Type"},
        {key: "ref", nom: "Réf"},
        {key: "infos", nom: "Infos"},
        {key: "montant", nom: "Montant", type: "number", suffix: "€"},
        {key: "comm_pourcent", nom: "Commission", type: "number", suffix: "%"},
        {key: "comm_euro", nom: "Commission", type: "number", suffix: "€"},
    ]

    useEffect(() => {
        queryCalculs();
    }, [anneeInput, utiliserPrime]);

    return (
        <div>

            <div className={"en-ligne spacebetween"}>
                <h3>
                    {commercial?.user?.nom_affichage} {commercial?.user?.nom_famille}
                </h3>
                <div className={"en-ligne"}>
                    <p>Résultats pour </p>
                    <NativeSelect
                        data={["2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"]}
                        value={anneeInput}
                        onChange={e => {
                            setAnneeInput(e.target.value)
                        }}
                    />
                    <Switch
                        label="Prendre en compte les primes ?"
                        checked={utiliserPrime}
                        onChange={e => {
                            setUtiliserPrime(e.target.checked);
                        }}
                    />
                </div>

                <div>&nbsp;</div>

                {/*<Loading_button style={{margin: "unset"}} is_loading={!resultat_calcul} onClick={queryCalculs}>Refresh</Loading_button>*/}
            </div>

            {!resultat_calcul ? (
                <Loading_div/>
            ) : (
                <>
                    {/*<p>SWITCH VUE RESULTAT/COMMISSIONS</p>*/}
                    {/*<p>Résultat ventes</p>*/}
                    {/*<p>Résultat abonnements</p>*/}
                    {/*<p>Résultat années précédentes</p>*/}
                    {/*<p>Résultat TOTAUX</p>*/}

                    {/*<p>GRAPH CAPTEURS VENDU PAR MOIS</p>*/}
                    {/*<p>GRAPH ABONNEMENTS PAYE PAR MOIS</p>*/}

                    {/*<p>TABLEAU AVEC VENTES ET ABONNEMENT PAYE</p>*/}


                    {!resultat_calcul ? (
                        <Loading_div/>
                    ) : (
                        <>
                            <div className={"cartes_resultat_calcul_wrapper"}>

                                {anneeInput !== new DateTZ(resultat_calcul.dateInput).getUTCFullYear() + "" && (
                                    <div className={"cartes_resultat_calcul"}>
                                        <div className={"carte"} style={{width: "100%"}}>
                                            <p>Résultats
                                                au {afficherDate(resultat_calcul.dateInput)}. {resultat_calcul.utiliserPrime ? "Primes appliqués." : ""}</p>
                                        </div>
                                    </div>
                                )}
                                <div className={"cartes_resultat_calcul"}>
                                    <div className={"carte"}>
                                        <p>{resultat_calcul.capteurs_cette_annee.length} Enregistreurs vendu cette
                                            année</p>
                                        <p className={"prix"}>{resultat_calcul.ventes}€</p>
                                        <div className={"en-ligne spacebetween"}>
                                            <p>{resultat_calcul.comm_pourcentage_vente}%</p>
                                            <p>{resultat_calcul.comm_vente}€</p>
                                        </div>
                                    </div>
                                    <div className={"carte"}>
                                        <p>{resultat_calcul.paiements_abo_cette_annee.length} Abonnements des
                                            enregistreurs
                                            vendus cette année</p>
                                        <p className={"prix"}>{resultat_calcul.abonnement_cette_annee}€</p>
                                        <div className={"en-ligne spacebetween"}>
                                            <p>{resultat_calcul.comm_pourcentage_abonnement}%</p>
                                            <p>{resultat_calcul.comm_abonnement}€</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"cartes_resultat_calcul"}>
                                    <div className={"carte"}>
                                        <p>{commercial?.ventesCustom?.filter(v => new DateTZ(v.date).getFullYear()+"" === anneeInput).length} Ventes custom</p>
                                        <p className={"prix"}>{resultat_calcul.ventes_custom}€</p>
                                        <div className={"en-ligne spacebetween"}>
                                            <p>Commission à % variable</p>
                                            <p>{resultat_calcul.comm_ventes_custom}€</p>
                                        </div>
                                    </div>
                                    <div className={"carte"}>
                                        <p>{resultat_calcul.capteurs_annee_precedentes.length} Enregistreurs vendu les
                                            années
                                            précédentes</p>
                                        <p>{resultat_calcul.paiements_abo_annee_precedente.length} Abonnements des
                                            enregistreurs
                                            vendu les années précédentes</p>
                                        <p className={"prix"}>{resultat_calcul.abonnement_annee_precedente}€</p>
                                        <div className={"en-ligne spacebetween"}>
                                            <p>{resultat_calcul.comm_pourcentage_annee_precedentes}%</p>
                                            <p>{resultat_calcul.comm_annee_precedentes}€</p>
                                        </div>

                                    </div>
                                </div>
                                <div className={"cartes_resultat_calcul"}>
                                    <div className={"carte"}>
                                        <p className={"prix"}>{Math.round((resultat_calcul.ventes + resultat_calcul.abonnement_cette_annee + resultat_calcul.abonnement_annee_precedente + resultat_calcul.ventes_custom) * 100) / 100}€
                                            généré cette année</p>
                                        <p style={{textAlign: "center"}}>Commissions totales pour l'année
                                            : {resultat_calcul.total_a_payer_au_commercial}€</p>
                                    </div>
                                    <div className={"carte"}>
                                        <p className={"prix"}>{resultat_calcul.restant_a_paye}€ restant à payer</p>
                                        <p style={{textAlign: "center"}}>{resultat_calcul.a_deja_ete_paye}€ déjà payé</p>
                                    </div>
                                </div>
                                <div className={"en-ligne"}>

                                </div>

                                {/*<pre>{JSON.stringify(resultat_calcul, null, 2)}</pre>*/}


                            </div>


                            <LineChart
                                h={200}
                                mt={30}
                                data={Array.from({length: 12}, (_, i) => {
                                    let mois_loop = i + 1
                                    const shortFrenchMonths = [
                                        "janv.", "févr.", "mars", "avr.", "mai", "juin",
                                        "juil.", "août", "sept.", "oct.", "nov.", "déc."
                                    ];

                                    const current_year = new DateTZ().getFullYear();
                                    const current_month = new DateTZ().getMonth() + 1;
                                    let retour_si_0: number | null = 0;

                                    if (Number(anneeInput) >= current_year && mois_loop >= current_month) {
                                        retour_si_0 = null;
                                    }

                                    let capteurs_ce_mois = resultat_calcul.capteurs_cette_annee.filter((c) => {
                                        let mois_vente = new DateTZ(c.dateDeVente)
                                        return (mois_vente.getMonth() + 1 + " " + mois_vente.getFullYear() === mois_loop + " " + anneeInput)
                                    });

                                    let ventes = capteurs_ce_mois.length;
                                    let ca_vente = capteurs_ce_mois.reduce((a, b) => a + b.prixDeVente, 0);

                                    let abonnements_ce_mois = resultat_calcul.paiements_abo_cette_annee.filter((c) => {
                                        let mois_vente = new DateTZ(c.payeLe)
                                        return (mois_vente.getMonth() + 1 + " " + mois_vente.getFullYear() === mois_loop + " " + anneeInput)
                                    });
                                    let ca_abo = abonnements_ce_mois.reduce((a, b) => a + b.payeMontant, 0);

                                    let abonnements_annee_precedentes_ce_mois = resultat_calcul.paiements_abo_annee_precedente.filter((c) => {
                                        let mois_vente = new DateTZ(c.payeLe)
                                        return (mois_vente.getMonth() + 1 + " " + mois_vente.getFullYear() === mois_loop + " " + anneeInput)
                                    });
                                    let ca_abo_ap = abonnements_annee_precedentes_ce_mois.reduce((a, b) => a + b.payeMontant, 0);

                                    let vente_custom_ce_mois = commercial?.ventesCustom.filter((c) => {
                                        let mois_vente = new DateTZ(c.date)
                                        return (mois_vente.getMonth() + 1 + " " + mois_vente.getFullYear() === mois_loop + " " + anneeInput)
                                    });
                                    let ca_ventes_custom = vente_custom_ce_mois?.reduce((a, b) => a + b.prix, 0) ?? 0;



                                    let ca = Math.round((ca_vente + ca_abo + ca_abo_ap + ca_ventes_custom)*100)/100;

                                    let nbe_abo = abonnements_ce_mois.length + abonnements_annee_precedentes_ce_mois.length;

                                    return {
                                        date: shortFrenchMonths[mois_loop - 1],
                                        CA: ca === 0 ? retour_si_0 : ca,
                                        Ventes: ventes + (vente_custom_ce_mois?.length??0),
                                        Abonnements: nbe_abo,
                                    }
                                })}
                                dataKey="date"
                                series={[
                                    {name: 'CA', color: 'blue.6'},
                                ]}
                                curveType="linear"
                                tickLine="none"
                                gridAxis="xy"
                                valueFormatter={(value) => value + "€"}
                                tooltipProps={{
                                    content: ({label, payload}) => <ChartTooltip label={label} payload={payload}/>,
                                }}
                            />

                        </>


                    )}


                    <Tableau_helper
                        typeDonnee={capteurs_tableau}
                        donnees={donnees_tableau_recap}
                    />
                </>
            )}

        </div>

    );
}

interface ChartTooltipProps {
    label: string;
    payload: Record<string, any>[] | undefined;
}

function ChartTooltip({label, payload}: ChartTooltipProps) {
    if (!payload) return null;

    return (
        <Paper px="md" py="sm" withBorder shadow="md" radius="md">
            <Text fw={500} mb={5}>
                {label}
            </Text>
            {/*{JSON.stringify(payload[0], null, 2)}*/}
            {getFilteredChartTooltipPayload(payload).map((item: any) => (
                <>
                    <Text key={item.name} c={item.color} fz="sm">
                        {item.name}: {item.value}€
                    </Text>
                    <Text key={item.name} c={item.color} fz="sm">
                        {item.payload.Ventes} ventes
                    </Text>
                    <Text key={item.name} c={item.color} fz="sm">
                        {item.payload.Abonnements} abonnements
                    </Text>
                </>
            ))}
        </Paper>
    );
}
