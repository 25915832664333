// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage, isSupported} from 'firebase/messaging';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDwZrEvfVtTw8LFfYiQPCV1QPrWXcN7Wqc",
    authDomain: "freshtime-54364.firebaseapp.com",
    projectId: "freshtime-54364",
    storageBucket: "freshtime-54364.firebasestorage.app",
    messagingSenderId: "1085065603157",
    appId: "1:1085065603157:web:e1a23c90cd2fa53da40cdc"
};

// Initialize Firebase

const firebase_app = initializeApp(firebaseConfig);


const firebase_vapid_key = "BLMqOcwZbk5kdefYolTkAQdD_9nuU06mP7EV__ctAGguZRmp2Q9We1yU4im-aWeVdu9JGywlgV4vRgXNKxNGNrM"

export const requestForToken = async () => {
    if (await isSupported()) {
        const messaging = getMessaging();
        return getToken(messaging, {vapidKey: firebase_vapid_key})
            .then((currentToken) => {
                if (currentToken === undefined) {
                    alert("Un problème est survenue. Assurez vous que n'avez pas bloquer l'autorisation pour les notifications. Vous pouvez aussi  vider le cache de l'application/site web.");
                }else{
                    if (currentToken) {
                        console.log('current token for client: ', currentToken);
                        return currentToken;
                        // Perform any other neccessary action with the token
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                        return null;
                    }
                }

            })
            .catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                return null
            });
    } else {
        console.log('Firebase is not supported');
        return null;
    }

};

export const onMessageListener = async (callback: (payload: any) => void) => {
    // Fait dans initital_requests (services > init & router > initital_requests.tsx


    // if (await isSupported()) {
    //     const messaging = getMessaging();
    //     console.log("messaging in messaging");
    //     onMessage(messaging, (payload) => {
    //         console.log("Received message payload:", payload);
    //         callback(payload); // Execute the callback with the message payload
    //     });
    // } else {
    //     console.warn("Messaging is not supported on this browser.");
    // }
};

export const checkExistingToken = async () => {
    if (await isSupported()) {
        try {
            const permission = Notification.permission;
            if (permission === "granted") {
                const messaging = getMessaging();
                const token = await getToken(messaging, {vapidKey: firebase_vapid_key});
                if (token) {
                    console.log('Existing FCM Token:', token);
                    return token
                    // Compare this token with the one stored in your backend
                } else {
                    console.log('No existing FCM token found.');
                    return null;
                }
            } else if (permission === "denied") {
                return "denied";
            } else {
                return null;
            }


        } catch (error) {
            console.error('Error retrieving FCM token:', error);
        }
    }

};