import {DateTZ} from "./GestionDates";
import {store} from "../store/store";

export function checkSiOrgaAMoyenDePaiement(organisation_id: number) {
    let cette_orga = store.getState().organisation.list.find(o => o.id === organisation_id);
    if (!cette_orga) return false;

    if (cette_orga.balance > 0) return true;

    let CB_perime_date = cette_orga.cBPerime ? new DateTZ(cette_orga.cBPerime) : null;
    let CB_perime_comp = CB_perime_date ? CB_perime_date?.getFullYear() + "" + CB_perime_date?.getMonth() : null
    let now_comp = new DateTZ().getFullYear() + "" + new DateTZ().getMonth()
    if (!CB_perime_comp) return false;
    return (CB_perime_comp > now_comp);
}