const env_config = {
    api_url : "https://api.freshtime.fr",
    // api_url : "https://localhost:8000",


    refresh_rate: 30 * 1000 , //En ms, auto refresh des mesures sur la page d'accueil et quelques autres pages
    refresh_rate_en_direct: 30 * 1000 , //En ms, auto refresh des mesures sur la page en direct
    refresh_rate_mode_installation: 10 * 1000 , //En ms, auto refresh des mesures sur les cellules configurées sans mesures
    notifications_duree_affichage : 10000 ,

    duree_affichage_deconnexion_capteur : 22 * 60 * 1000 , // En millisecondes
    duree_affichage_deconnexion_gateway : 22 * 60 * 1000 , // En millisecondes

    texte_bandeau_affichage_paiement: "Votre organisation est activée!\n" +
        "Vous êtes libre de faire les configurations que vous souhaitez jusqu'à ce soir minuit.\n" +
        "Attention : Une fois vos configurations terminées, vous devez régler l'avance pour le mois à venir avant la date butoir, sous peine de voir vos services désactivés.",

    url_cg_vente: "https://docs.freshtime.fr/fr/Contractuel/conditions_generales_de_vente",
    url_cg_utilisation: "https://docs.freshtime.fr/fr/Contractuel/condition_general_utilisateur",
    url_cookies: "https://docs.freshtime.fr/fr/Contractuel/cookies-localstorage",
    url_mentions_legales: "https://docs.freshtime.fr/fr/Contractuel/mentions_legales",
    url_politique_confidentialite: "https://docs.freshtime.fr/fr/Contractuel/politique-de-confidentialite",
};

export default env_config;