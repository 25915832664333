import React, {useEffect, useState} from "react";

import ajax from "../../../../services/AxiosInterceptor";
import {Code, Modal} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import env_config from "../../../../env_config";
import {Tableau_helper, typeDonnee} from "../../../../components/tableau/Tableau_helper";
import {AxiosResponse} from "axios";
import {IconeCheck} from "../../../../components/icone/solid_react/check";
import {IconeEye} from "../../../../components/icone/solid_react/eye";
import {Statut_cellule} from "../../../../types/statut_cellule";
import {Codes_promo} from "../../../../types/codes_promo";
import {useDisclosure} from "@mantine/hooks";

export function Codes_promo_admin({statuts}:{statuts:Statut_cellule[]}) {

    const [donnees, setDonnees] = useState<Codes_promo[] | null>(() => null);

    const [modale_detail_is_open, modale_detail_handlers] = useDisclosure(false);
    const [modaleDetail, setModaleDetail] = useState("Aucun détail.");

    function ouvrirModaleDetail(detail:string)
    {
        setModaleDetail(detail);
        modale_detail_handlers.open();
    }

    const Codes_promo_Tableau_admin:typeDonnee[] = [
        {key: "id", nom: "ID", noedit: true},
        {key: "code", nom: "Code", required:true},
        {key: "statut_cellule_id", nom: "Abonnement", wrapper: (texte:number) => {
            let statut = statuts.find(s => s.id == texte);
            if (!statut) return <>Erreur: Statut introuvable</>
            return (
                    <>{statut.nom} ({statut.prix_achat}€)</>)
            },
            // spanClassname: "no-wrap flex_align_center",
            desc: "Abonnement a appliquer",
            type: "select", select_searchable: true,
            select_data: statuts.map(s => {
                return {"value": s.id+"", "label" : s.nom + " (" + s.prix_achat + "€/mois)"}
            }), required:true
        },
        {key: "debut_validite", nom: "Début de validité",
            desc: "Date à partir de laquelle le code promo est disponible",
            type: "date", required:true
        },
        {key: "fin_validite", nom: "Fin de validité",
            desc: "Date jusqu'à laquelle le code promo est disponible",
            type: "date", required:true
        },
        {key: "duree_statut", nom: "Durée d'application", required:true,
            desc: "Durée, en JOURS, durant laquelle l'abonnement sera appliqué sur la cellule du client.",
        },
        {key: "cellules_utilise", nom: "Utilisation",
            wrapper: (texte:string) => {
                if (!texte || (texte.match(/\bOrganisation\b/gi) || []).length === 0) return <>Aucune</>
                return (
                    <button onClick={() => ouvrirModaleDetail("Code promotionnel utilisé sur ces cellules : "+texte)}><IconeEye /> {(texte.match(/\bOrganisation\b/gi) || []).length}</button>
                )
            },
            noedit: true
        },
        {key: "usage_unique", nom: "Usage unique", type: "boolean",
            desc: "Si activé, ce code promo ne pourra être utilisé qu'une seule fois.",
        },
        {key: "un_par_orga", nom: "Une fois par organisation", type: "boolean",
            desc: "Si activé, ce code promo ne pourra être utilisé qu'une seule fois par organisation.",
        },
        {key: "un_par_cellule", nom: "Une fois par cellule", type: "boolean",
            desc: "Si activé, ce code promo ne pourra être utilisé qu'une seule fois dans chaque cellule.",
        },
        {key: "nbe_fois_par_enregistreur", nom: "Nombre d'activation possible par enregistreur",
            desc: "Ne pas laisser vide, pour désactiver mettre une valeur haute par exemple 10.",
        },
        {key: "date_fin_definie_par_utilisation_precedente_sur_enregistreur", nom: "date de fin définie par l'utilisation précedente sur l'enregistreur", type: "boolean",
            desc: "Si activé, la date de fin de chaque activation sur un même enregistreur aura toujours la date de fin de la plus ancienne activation.",
        },
        {key: "categorie_abonnement", nom: "categorie_abonnement",
            desc: "Limiter à une catégorie d'abonnement (vendu, loué, ...). Laisser vide pour ne pas prendre en compte.",
        },
        {action_editer: true},
    ]
    function handleEnregistrer(code:Codes_promo|null, setIsLoading:(isLoading:boolean) => void) {

        if (!code)
        {
            alert( "Erreur, code promo est : " + JSON.stringify(code))
            return;
        }
        setIsLoading(true);
        let ajax_response = (response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Validé!",
                message: 'L\'offre est enregistré',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (code.id < 0){
                let new_statut:Codes_promo = response.data.codespromo;
                if (donnees) setDonnees([...donnees, new_statut])
            }else{
                if (donnees) setDonnees(donnees?.map(donnee => {
                    if (donnee.id === code.id){
                        return code
                    }
                    return donnee;
                }))
            }
        }
        if (code?.id < 0)
        {
            ajax.post("/codespromos", code).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        } else{
            ajax.put("/codespromos/" + code.id, code).then(ajax_response).catch(error => {
                setIsLoading(false);
            });
        }

    }

    function handleSupprimer(codesPromo: Codes_promo, setIsLoading: (isLoading: boolean) => void) {


        if (!codesPromo)
        {
            alert( "Erreur, code promo est : " + JSON.stringify(codesPromo))
            return;
        }
        setIsLoading(true);
        ajax.delete("/codespromos/" + codesPromo.id).then((response:AxiosResponse<any, any>) => {
            notifications.show({
                // id: 'enregistre',
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'orange',
                title: "Validé!",
                message: 'Le code promo a été supprimé',
                icon: <IconeCheck/>,
                // className: 'my-notification-class',
                loading: false,
            });
            setIsLoading(false);

            if (donnees) setDonnees(donnees.filter(donnee => donnee.id !== codesPromo.id))
        }).catch(error => {
            setIsLoading(false);
        });


    }

    useEffect(() => {
        ajax.get("/codespromos").then(response => setDonnees(response.data.codespromos)).catch(e => {
        })
    }, []);

    let format_new:Codes_promo = {
        cellules_utilise: "",
        code: "",
        debut_validite: new Date().toISOString(),
        fin_validite: new Date().toISOString(),
        duree_statut: 0,
        id: -1,
        statut_cellule: statuts[0],
        statut_cellule_id: statuts[0].id,
        un_par_cellule: false,
        un_par_orga: false,
        usage_unique: false,
        date_fin_definie_par_utilisation_precedente_sur_enregistreur: false,
        categorie_abonnement: "",
        nbe_fois_par_enregistreur: 10,

    }

    return (
        <>
            <Tableau_helper
                typeDonnee={Codes_promo_Tableau_admin}
                donnees={donnees}
                handleEnregistrer={handleEnregistrer}
                formatNew={format_new}
                handleSupprimer={handleSupprimer}
            />
            <Modal opened={modale_detail_is_open} onClose={modale_detail_handlers.close} title="Détails">
                <Code block>{modaleDetail}</Code>
            </Modal>
        </>



    );
}
