import React, {forwardRef, useRef, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import ajax from "../../../services/AxiosInterceptor";
import {Avatar, Group, Modal, Select, Textarea, TextInput} from "@mantine/core";
import {Loading_button} from "../../../components/loading_button/loading_button";
import {Organisation} from "../../../types/organisation";
import {notifications} from "@mantine/notifications";
import env_config from "../../../env_config";
import {IconeCheck} from "../../../components/icone/solid_react/check";
import {IconeToggleOff} from "../../../components/icone/solid_react/toggle-off";
import {IconeToggleOn} from "../../../components/icone/solid_react/toggle-on";

export function Modale_desactiver_organisation({
                                                   organisation,
                                                   setOrganisation
                                               }: { organisation: Organisation, setOrganisation: (orga: Organisation) => void }) {
    const [opened, handlers] = useDisclosure(false);
    const [btn_loading, set_btn_loading] = useState(false);

    const [texte_a_afficher, setTexte_a_afficher] = useState( organisation.desactivation_texte ? organisation.desactivation_texte :
        "Vos services ont été temporairement désactivés, " +
        "conformément à l'article 6 de nos conditions générales de ventes, " +
        "en attendant la réception du règlement de votre commande.");


    const form_ref = useRef<HTMLFormElement | null>(null);

    function handleEnregistrer() {
        if (!form_ref.current?.checkValidity()) {
            form_ref.current?.reportValidity();
            return;
        }

        ajax.post("/organisations/" + organisation.id + "/desactiver", {
            "texte_a_afficher": texte_a_afficher
        }).then(response => {
            let orga_maj = {...organisation};
            orga_maj.desactivation_texte = texte_a_afficher;
            setOrganisation(orga_maj);
            handlers.close();
            set_btn_loading(false);
        }).catch(error => {
            set_btn_loading(false);
            handlers.close();
        })

        set_btn_loading(true);
    }

    function handleReactiver() {

        ajax.post("/organisations/" + organisation.id + "/activer", {}).then(response => {
            let orga_maj = {...organisation};
            orga_maj.desactivation_texte = null;
            setOrganisation(orga_maj);
            handlers.close();
            set_btn_loading(false);
            notifications.show({
                withCloseButton: true,
                autoClose: env_config.notifications_duree_affichage,
                color: 'green',
                title: "Activée",
                message: "L'organisation à été réactivée",
                icon: <IconeCheck/>,
            });
        }).catch(error => {
            set_btn_loading(false);
        })

        set_btn_loading(true);
    }


    return (
        <>
            {organisation.desactivation_texte ? (
                <>
                    <button className={"sec"} onClick={handlers.open}><IconeToggleOff/>Changer le texte
                    </button>
                    <Loading_button is_loading={btn_loading} className={"sec"} onClick={handleReactiver}><IconeToggleOn/>Réactiver</Loading_button>
                </>

            ) : (
                <button className={"sec"} onClick={handlers.open}><IconeToggleOff/>Désactiver</button>
            )}


            <Modal opened={opened} onClose={handlers.close} title="Désactiver l'organisation">
                <form className={"form"} ref={form_ref} onSubmit={event => event.preventDefault()}>

                    <Textarea label={"Texte à afficher aux utilisateurs : "}
                              autosize
                              value={texte_a_afficher}
                              onChange={event => setTexte_a_afficher(event.target.value)}
                              required={true}/>


                    <Loading_button is_loading={btn_loading}
                                    onClick={handleEnregistrer} icone={"toggle-off"}>Désactiver
                        l'organisation</Loading_button>
                </form>

            </Modal>
        </>

    )
}